import React from "react";
import { Box, Chip, Typography, useTheme } from "@mui/material";

const getRandomItem = (array) =>
  array[Math.floor(Math.random() * array.length)];

const ChipList = ({ items, random_colors }) => {
  const theme = useTheme();
  let itemList;
  if (Array.isArray(items)) {
    itemList = items; // It's already a list
  } else if (typeof items === "object" && items !== null) {
    itemList = Object.keys(items); // It's an object, get the keys
  } else {
    itemList = []; // Default to an empty list if neither
  }

  // Define pairs of background and text colors
  const colorPairs = [
    {
      background: theme.palette.primary.main,
      text: theme.palette.neutral.black,
      borders: theme.palette.neutral[700],
    },
    {
      background: theme.palette.neutral.black,
      text: theme.palette.neutral.white,
      borders: theme.palette.neutral.black,
    },
    {
      background: theme.palette.neutral.white,
      text: theme.palette.neutral.black,
      borders: theme.palette.neutral.gray,
    },
  ];

  return (
    <Box>
      <Box display="flex" gap={1} flexWrap="wrap">
        {itemList.map((item, index) => {
          let colorPair = { background: "default", text: "default" };

          if (random_colors) {
            colorPair = getRandomItem(colorPairs);
          }

          return (
            <Chip
              key={index}
              label={item}
              size="small"
              sx={{
                backgroundColor: colorPair.background,
                borderColor: colorPair.borders,
                color: colorPair.text,
              }}
              variant="outlined"
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ChipList;

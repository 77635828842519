import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";

const ClientProfiling = ({ profileData }) => {
  return (
    <Box py={1}>
      <List>
        {Object.entries(profileData).map(([key, value], index) => (
          <React.Fragment key={index}>
            <ListItem sx={{ px: 0, py: 0.5 }}>
              <Box display="flex" flexDirection="column" width="100%">
                <Typography variant="body2" color="textSecondary" align="left">
                  {key}
                </Typography>

                <Typography
                  variant="body1"
                  // fontWeight="bold"
                  align="right"
                  sx={{ px: 2 }}
                >
                  {value === "" ? "not mentioned" : value}
                </Typography>
              </Box>
            </ListItem>
            {index < Object.entries(profileData).length - 1 && (
              <Divider component="li" />
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ClientProfiling;

import React from "react";
import { Box, Typography, Stack, useTheme } from "@mui/material";

const MetricCircles = ({ metrics }) => {
  const theme = useTheme();
  const circles = [
    {
      size: 110,
      text: "Agent/Client ratio",
      value: `${Math.floor(metrics.time_ratio * 100)}%`,
      background: `${theme.palette.neutral.black}`,
      color: `${theme.palette.neutral.white}`,
      position: "center",
    },
    {
      size: 75,
      text: "Silence duration",
      value: `${Math.floor(metrics.silence_duration / 60)}m ${Math.floor(
        metrics.silence_duration % 60
      )}s`,
      background: `${theme.palette.primary.main}`,
      color: `${theme.palette.neutral.black}`,
      position: "topLeft",
    },
    {
      size: 70,
      text: "Closing attempts",
      value: metrics.closing_attempts.toString(),
      background: `${theme.palette.neutral.black}`,
      color: `${theme.palette.neutral.white}`,
      position: "bottomRight",
    },
    {
      size: 80,
      text: "Client engagement",
      value: `${metrics.client_engagement * 100}%`,
      background: `${theme.palette.background.default}`,
      color: `${theme.palette.text.primary}`,
      position: "topRight",
    },
  ];

  const getPositionStyles = (position) => {
    switch (position) {
      case "center":
        return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
      case "topLeft":
        return { top: 10, left: 0, transform: "translate(0, 0)" };
      case "bottomRight":
        return { bottom: 0, right: 20, transform: "translate(0, 0)" };
      case "topRight":
        return { top: 0, right: 0, transform: "translate(0, 0)" };
      default:
        return {};
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {circles.map((circle, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            width: circle.size,
            height: circle.size,
            borderRadius: "50%",
            backgroundColor: circle.background,
            border: `3px solid ${theme.palette.background.widget}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...getPositionStyles(circle.position),
          }}
        >
          <Typography
            variant="body3"
            sx={{
              padding: 1,
              color: circle.color,
              textAlign: "center",
            }}
          >
            {circle.text}

            <br />
            <span style={{ paddingTop: 0.5 }}>
              <strong>{circle.value}</strong>
            </span>
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MetricCircles;

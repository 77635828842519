import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  useTheme,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Plus, TrashSimple, UserPlus } from "@phosphor-icons/react";
import {
  useCreateAgentMutation,
  useGetAgentsQuery,
  useGetLanguagesQuery,
  useDeleteAgentMutation,
} from "../state/api";
import CustomFab from "./CustomFab";

const AgentCard = ({ agent, onDelete }) => {
  const [deleteAgent] = useDeleteAgentMutation();
  const handleDelete = async () => {
    try {
      await deleteAgent(agent._id).unwrap();
      onDelete(); // Optionally, call the onDelete callback to update the UI
    } catch (err) {
      console.error("Failed to delete agent", err);
    }
  };
  return (
    <Card
      variant="outlined"
      raised={true}
      sx={{ marginBottom: 1, display: "flex", alignItems: "center" }}
    >
      <Avatar
        src={agent.avatar}
        alt={agent.name}
        sx={{ width: 50, height: 50, borderRadius: "50%", ml: 2 }}
      >
        {!agent.avatar && agent.name ? agent.name[0].toUpperCase() : null}
      </Avatar>
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          py: "0 !important",
        }}
      >
        <Box
          gap={5}
          display="flex"
          flexDirection={"row"}
          alignItems="center"
          py={1}
          width="100%"
        >
          <Stack sx={{ py: 1, width: "20%" }}>
            <Typography variant="body2" color="textSecondary">
              ID {agent.external_id}
            </Typography>
            <Typography variant="h3">{agent.name}</Typography>
          </Stack>
          <Stack>
            <Typography variant="body">{agent.email}</Typography>
            <Typography variant="body2" color="textSecondary">
              {agent.phone_number}
            </Typography>
          </Stack>
        </Box>
        <IconButton onClick={handleDelete}>
          <TrashSimple size={18} />
        </IconButton>
      </CardContent>
    </Card>
  );
};

const AddAgentDialog = ({ open, onClose, onAddAgent, teamName }) => {
  const theme = useTheme();
  const [fullName, setFullName] = useState("");
  const [language, setLanguage] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [internalID, setinternalID] = useState("");
  const [createAgent] = useCreateAgentMutation();

  const { data: languages = [], loading } = useGetLanguagesQuery();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (fullName && email && phone && internalID && language) {
      const newAgent = {
        name: fullName,
        email,
        language,
        phone_number: phone,
        team: teamName,
        avatar: "avatar_src",
        external_id: internalID,
      };

      try {
        await createAgent(newAgent).unwrap();
        onAddAgent(newAgent);
        setFullName("");
        setEmail("");
        setPhone("");
        onClose();
      } catch (err) {
        console.error("Failed to create agent", err);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Agent</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Internal ID"
            value={internalID}
            onChange={(e) => setinternalID(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
              labelId="language-select-label"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              label="Language"
              disabled={loading}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Add Agent
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddTeamDialog = ({ open, onClose, onAddTeam }) => {
  const theme = useTheme();
  const [teamName, setTeamName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (teamName) {
      onAddTeam(teamName);
      setTeamName("");
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Team</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Team Name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            fullWidth
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: theme.palette.neutral.black }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ color: theme.palette.neutral.black }}
        >
          Add Team
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Teams = () => {
  const theme = useTheme();
  const { data: agents = [], isLoading } = useGetAgentsQuery();
  const [teams, setTeams] = useState([]);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);
  const [isAgentDialogOpen, setIsAgentDialogOpen] = useState(false);
  const [isTeamDialogOpen, setIsTeamDialogOpen] = useState(false);
  const [currentTeamName, setCurrentTeamName] = useState("");

  useEffect(() => {
    if (!agents.length) {
      setTeams([]);
      return;
    }

    const teamMap = agents.reduce((acc, agent) => {
      const team = agent.team || "Unassigned";
      if (!acc[team]) acc[team] = [];
      acc[team].push(agent);
      return acc;
    }, {});

    const teamArray = Object.keys(teamMap)
      .filter((team) => team !== "Unassigned")
      .map((team) => ({
        name: team,
        agents: teamMap[team],
      }));

    setTeams(teamArray);
  }, [agents]);

  const handleAddTeam = (teamName) => {
    const newTeam = { name: teamName, agents: [] };
    setTeams([...teams, newTeam]);
  };

  const handleAddAgent = (agent) => {
    const updatedTeams = teams.map((team, index) =>
      index === selectedTeamIndex
        ? { ...team, agents: [...team.agents, agent] }
        : team
    );
    setTeams(updatedTeams);
  };

  const handleDeleteAgent = (teamIndex, agentIndex) => {
    const updatedTeams = teams.map((team, index) =>
      index === teamIndex
        ? {
            ...team,
            agents: team.agents.filter((_, i) => i !== agentIndex),
          }
        : team
    );
    setTeams(updatedTeams);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTeamIndex(newValue);
  };

  const handleAgentDialogOpen = ({ teamName }) => {
    setIsAgentDialogOpen(true);
    setCurrentTeamName(teams[selectedTeamIndex]?.name);
  };

  const handleAgentDialogClose = () => {
    setIsAgentDialogOpen(false);
  };

  const handleTeamDialogOpen = () => {
    setIsTeamDialogOpen(true);
  };

  const handleTeamDialogClose = () => {
    setIsTeamDialogOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        py: theme.spacing(1),
        px: theme.spacing(0.5),
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={selectedTeamIndex}
        onChange={handleTabChange}
        sx={{ borderRight: 1, borderColor: "divider", minWidth: "12%" }}
      >
        {teams.map((team, index) => (
          <Tab
            disableRipple
            key={index}
            label={<Typography variant="body">{team.name}</Typography>}
            sx={{ color: theme.palette.text.primary }}
          />
        ))}
        <Box
          sx={{
            py: theme.spacing(1.5),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Tooltip title="Add Team">
            <CustomFab
              size="small"
              onClick={handleTeamDialogOpen}
              disableRipple
              sx={{ color: theme.palette.neutral.white }}
            >
              <Plus size={18} />
            </CustomFab>
          </Tooltip>
        </Box>
      </Tabs>
      <Box sx={{ flexGrow: 1, px: theme.spacing(4), py: theme.spacing(1) }}>
        {teams.map((team, index) =>
          selectedTeamIndex === index ? (
            <Box key={index}>
              <Box
                width="100%"
                display="flex"
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={theme.spacing(1)}
              >
                <Typography variant="h2" gutterBottom>
                  {team.name}
                </Typography>
                <Stack direction="row">
                  <Tooltip title="Add Agent">
                    <CustomFab
                      size="small"
                      onClick={handleAgentDialogOpen}
                      disableRipple
                      sx={{ color: theme.palette.neutral.white }}
                    >
                      <UserPlus size={18} />
                    </CustomFab>
                  </Tooltip>
                </Stack>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {team.agents.map((agent, agentIndex) => (
                  <AgentCard
                    key={agentIndex}
                    agent={agent}
                    onDelete={() => handleDeleteAgent(index, agentIndex)}
                  />
                ))}
              </Box>
            </Box>
          ) : null
        )}
      </Box>
      <AddAgentDialog
        open={isAgentDialogOpen}
        onClose={handleAgentDialogClose}
        onAddAgent={handleAddAgent}
        teamName={currentTeamName}
      />
      <AddTeamDialog
        open={isTeamDialogOpen}
        onClose={handleTeamDialogClose}
        onAddTeam={handleAddTeam}
      />
    </Box>
  );
};

export default Teams;

import React from "react";
import { faker } from "@faker-js/faker";
import {
  List,
  ListItem,
  Avatar,
  Typography,
  Divider,
  Box,
  Stack,
  useTheme,
} from "@mui/material";
import FlexBetween from "./FlexBetween";

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num - 1) + "...";
};

const LeaderBoard = ({ agent_leaderboard = [] }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const colors = [
    {
      background: isDarkMode
        ? theme.palette.background.default
        : theme.palette.neutral.black,
      text: theme.palette.neutral.white,
    },
    {
      background: isDarkMode
        ? theme.palette.primary.main
        : theme.palette.primary.main,
      text: isDarkMode
        ? theme.palette.neutral.black
        : theme.palette.neutral.black,
    },
    {
      background: isDarkMode
        ? theme.palette.background.widget
        : theme.palette.background.widget,
      text: isDarkMode
        ? theme.palette.neutral.white
        : theme.palette.neutral.black,
    },
  ];
  return (
    <Box px={0} sx={{ width: "100%" }}>
      <Box marginBottom={4} marginTop={0.7}>
        <Typography variant="body2" color="textSecondary">
          Average scores of best performing agents.
        </Typography>
      </Box>
      {agent_leaderboard.map((agent, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
          paddingBottom={1.5}
          sx={{
            position: "relative",
            borderRadius: theme.shape.borderRadius / 16,
            color: colors[index].text,
            backgroundColor: colors[index].background,
            marginTop: "-10px",
            transition: "transform 0.4s ease-in-out",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          }}
        >
          <Box display="flex" alignItems="center" padding={1}>
            <Avatar
              src={agent.avatar || "path_to_default_avatar"}
              alt={agent.name}
              sx={{ mr: 1, width: 30, height: 30 }}
            />
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                variant="body2"
                noWrap
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {truncateString(agent.name || "Unknown", 15)}
              </Typography>
              <Typography noWrap variant="body3" color="textSecondary">
                {truncateString(agent.team || "Unknown", 15)}
              </Typography>
            </Box>
          </Box>
          <Box paddingRight={0.7}>
            <Typography variant="body">
              <strong>{agent.average_score.toFixed(2)}</strong>
            </Typography>
          </Box>
        </Box>
      ))}
      <Box>
        <Typography>&nbsp;</Typography>
      </Box>
    </Box>
  );
};

export default LeaderBoard;

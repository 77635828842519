import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Plus, PencilSimple, TrashSimple } from "@phosphor-icons/react";
import CustomFab from "../CustomFab";

const fieldConfig = [
  { name: "name", label: "Name", type: "text" },
  { name: "description", label: "Description", type: "text" },
];

const GridSettings = ({ title, data = [], onUpdate }) => {
  const theme = useTheme();
  const [items, setItems] = useState(data);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [newItem, setNewItem] = useState(() => {
    const newObj = {};
    fieldConfig.forEach((field) => {
      newObj[field.name] = field.type === "array" ? [] : "";
    });
    return newObj;
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteInput, setDeleteInput] = useState("");
  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleOpenEditDialog = (item) => {
    setCurrentItem(item);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem((prevItem) => ({
      ...prevItem,
      [name]:
        fieldConfig.find((field) => field.name === name).type === "array"
          ? value.split(",").map((k) => k.trim())
          : value,
    }));
  };

  const handleSaveEdit = async () => {
    const updatedItems = items.map((item) =>
      item.id === currentItem.id ? currentItem : item
    );
    setItems(updatedItems);
    await onUpdate(updatedItems);
    handleCloseEditDialog();
  };

  const handleDelete = (id) => {
    setItemIdToDelete(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirmed = async (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
    await onUpdate(updatedItems);
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setNewItem(() => {
      const newObj = {};
      fieldConfig.forEach((field) => {
        newObj[field.name] = field.type === "array" ? [] : "";
      });
      return newObj;
    });
  };

  const handleAddItem = async () => {
    const newItemObj = {
      ...newItem,
      id: items.length ? Math.max(...items.map((s) => s.id)) + 1 : 1,
      ...fieldConfig.reduce((acc, field) => {
        if (field.type === "array") {
          acc[field.name] = newItem[field.name].split(",").map((k) => k.trim());
        }
        return acc;
      }, {}),
    };
    const updatedItems = [...items, newItemObj];
    setItems(updatedItems);
    await onUpdate(updatedItems);
    handleCloseAddDialog();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        width="100%"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 1,
          px: theme.spacing(2),
        }}
      >
        <Stack direction="column">
          <Typography variant="h2" color="textPrimary">
            {title}
          </Typography>
          <Typography variant="body3" color="textSecondary">
            Manage your {title.toLowerCase()} settings here.
          </Typography>
        </Stack>
        <Stack direction="row">
          <CustomFab disableRipple size="small" onClick={handleOpenAddDialog}>
            <Plus color={theme.palette.neutral.white} size={16} />
          </CustomFab>
        </Stack>
      </Box>
      <Grid container spacing={2} sx={{ py: theme.spacing(2) }}>
        {items.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <Card>
              <CardContent>
                {fieldConfig.map((field) => (
                  <Box
                    key={field.name}
                    onClick={() => handleOpenEditDialog(item)}
                    sx={{ cursor: "pointer", width: "100%" }}
                  >
                    {field.type === "array" ? (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {(item[field.name] ?? []).map((chip, index) => (
                          <Chip key={index} label={chip} size={"small"} />
                        ))}
                      </Box>
                    ) : (
                      <Typography
                        variant={
                          field.name === "description" ? "body2" : "body"
                        }
                        color={
                          field.name === "description"
                            ? "textSecondary"
                            : "textPrimary"
                        }
                      >
                        {item[field.name]}
                      </Typography>
                    )}
                  </Box>
                ))}
              </CardContent>
              <Box
                sx={{ padding: 1, display: "flex", justifyContent: "flex-end" }}
              >
                <IconButton onClick={() => handleOpenEditDialog(item)}>
                  <PencilSimple size={17} />
                </IconButton>
                <IconButton onClick={() => handleDelete(item.id)}>
                  <TrashSimple size={17} />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          {fieldConfig.map((field) => (
            <TextField
              key={field.name}
              label={field.label}
              name={field.name}
              fullWidth
              margin="normal"
              multiline={field.type === "text"}
              rows={field.type === "text" ? 2 : 1}
              value={
                field.type === "array"
                  ? (currentItem[field.name] ?? []).join(", ")
                  : currentItem[field.name]
              }
              onChange={handleEditChange}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent>
          {fieldConfig.map((field) => (
            <TextField
              key={field.name}
              label={field.label}
              fullWidth
              margin="normal"
              multiline={field.type === "text"}
              rows={field.type === "text" ? 2 : 1}
              value={newItem[field.name]}
              onChange={(e) =>
                setNewItem({ ...newItem, [field.name]: e.target.value })
              }
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>Cancel</Button>
          <Button onClick={handleAddItem} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            To prevent accidental delete please type 'delete' to confirm.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Type 'delete' to confirm"
            type="text"
            fullWidth
            value={deleteInput}
            onChange={(e) => setDeleteInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              if (deleteInput === "delete") {
                await handleDeleteConfirmed(itemIdToDelete);
                setOpenDeleteDialog(false);
                setDeleteInput("");
              }
            }}
            color="primary"
            disabled={deleteInput !== "delete"}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GridSettings;

import React from "react";
import { Box, Typography, Button } from "@mui/material";

const ConfirmationStep = ({ handleReset, theme }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      py={theme.spacing(1)}
      sx={{ height: "100%" }}
    >
      <Typography variant="body" color={theme.palette.primary.light}>
        All files were uploaded successfully.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" onClick={handleReset}>
          Upload More Files
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmationStep;

import { createSlice } from "@reduxjs/toolkit";
import { api } from "./api";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token"),
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCredentials: (state, { payload: { token, user } }) => {
      state.token = token;
      state.user = user;
      localStorage.setItem("token", token);
    },
    logOut: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("token");
      localStorage.removeItem("showFeedbackReminder");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchPending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.token = payload.access_token;
        localStorage.setItem("token", payload.access_token);
      }
    );
    builder.addMatcher(
      api.endpoints.login.matchRejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error;
      }
    );
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

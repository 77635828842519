import React from "react";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Button,
  Box,
} from "@mui/material";

const ManualSelectionForm = ({
  language,
  setLanguage,
  agent,
  setAgent,
  agents,
  team,
  theme,
  handleNext,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
      sx={{ width: "100%", height: "100%" }}
    >
      <Stack direction="column" spacing={2}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Language</InputLabel>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            label="Language"
            sx={{ color: theme.palette.neutral.white }}
          >
            <MenuItem value="English">English</MenuItem>
            <MenuItem value="Spanish">Spanish</MenuItem>
            <MenuItem value="French">French</MenuItem>
            <MenuItem value="German">German</MenuItem>
            <MenuItem value="Russian">Russian</MenuItem>
          </Select>
        </FormControl>

        <Autocomplete
          options={agents.map((agent) => agent.name)}
          value={agent}
          sx={{ color: theme.palette.neutral.white }}
          onChange={(event, newValue) => setAgent(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { color: theme.palette.neutral.white },
              }}
              label="Agent"
              fullWidth
              margin="normal"
              sx={{ color: theme.palette.neutral.white }}
            />
          )}
        />
        <TextField
          label="Team"
          value={team}
          InputProps={{
            readOnly: true,
            style: { color: theme.palette.neutral.white },
          }}
          fullWidth
          margin="normal"
          sx={{ color: theme.palette.neutral.white }}
        />
      </Stack>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ color: theme.palette.neutral.black }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ManualSelectionForm;

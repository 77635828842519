import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { CloudArrowUp } from "@phosphor-icons/react";
import { useDropzone } from "react-dropzone";

const InitialUploadStep = ({
  onDrop,
  uploadedFiles,
  errorMessage,
  handleNext,
  theme,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "audio/*",
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      py={theme.spacing(1)}
      sx={{ height: "100%" }}
    >
      <Box
        {...getRootProps()}
        flexGrow={1}
        sx={{
          borderRadius: theme.shape.borderRadius / 16,
          padding: 4,
          textAlign: "center",
          justifyContent: "center",
          cursor: "pointer",
          backgroundColor: isDragActive
            ? theme.palette.neutral[100]
            : theme.palette.neutral[0],
          marginBottom: 2,
        }}
      >
        <input {...getInputProps()} />
        <Box
          height="100%"
          gap={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloudArrowUp size={48} color={theme.palette.neutral.white} />
          <Typography variant="body" color={theme.palette.neutral.white}>
            Drag & Drop audio files here, or click to select files
          </Typography>
        </Box>
      </Box>
      {errorMessage && (
        <Typography color="error" variant="body2" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {uploadedFiles.length > 0 && (
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              maxHeight: 100,
              overflowY: "auto",
              marginBottom: 2,
              border: "1px solid grey",
              borderRadius: theme.shape.borderRadius / 16,
              padding: theme.spacing(1),
            }}
          >
            {uploadedFiles.map((file, index) => (
              <Typography
                key={index}
                variant="body2"
                color="textSecondary"
                noWrap
              >
                {file.name}
              </Typography>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body"
              color={theme.palette.neutral.white}
              gutterBottom
            >
              {uploadedFiles.length} file{uploadedFiles.length > 1 ? "s" : ""}{" "}
              will be processed
            </Typography>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ color: theme.palette.neutral.black }}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InitialUploadStep;

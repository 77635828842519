import React from "react";
import { IconButton, useTheme } from "@mui/material";
import { styled } from "@mui/system";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: `2px solid ${theme.palette.neutral.gray}`,
  //   borderRadius: theme.shape.borderRadius,

  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CustomIconButton = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <StyledIconButton theme={theme} {...props}>
      {children}
    </StyledIconButton>
  );
};

export default CustomIconButton;

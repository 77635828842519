import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Badge,
  Stack,
} from "@mui/material";
import {
  Handshake,
  Lock,
  WarningCircle,
  SmileySad,
} from "@phosphor-icons/react";
import { useTheme } from "@mui/material/styles";

const transformData = (data) => {
  return {
    agreements: {
      title: "Agreements",
      items: data.agreements || [],
    },
    commitments: {
      title: "Commitments",
      items: data.commitments || [],
    },
    objections: {
      title: "Objections",
      items: data.objections || [],
    },
    painPoints: {
      title: "Pain Points",
      items: data.pain_points || [],
    },
  };
};

const Acop = ({ data }) => {
  const [currentContent, setCurrentContent] = useState("agreements");
  const theme = useTheme();

  const iconMapping = {
    agreements: <Handshake color={theme.palette.text.primary} size={24} />,
    commitments: <Lock color={theme.palette.text.primary} size={24} />,
    objections: <WarningCircle color={theme.palette.text.primary} size={24} />,
    painPoints: <SmileySad color={theme.palette.text.primary} size={24} />,
  };

  const handleContentChange = (contentType) => {
    setCurrentContent(contentType);
  };

  const transformedData = transformData(data);
  const content = transformedData[currentContent];

  return (
    <Box height="100%" width="100%">
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          paddingTop={1}
        >
          <IconButton
            sx={{ color: theme.palette.neutral.alternative }}
            onClick={() => handleContentChange("agreements")}
          >
            <Badge
              badgeContent={transformedData.agreements.items.length}
              color="primary"
            >
              <Handshake color={theme.palette.text.primary} size={24} />
            </Badge>
          </IconButton>

          <IconButton
            sx={{ color: theme.palette.neutral.alternative }}
            onClick={() => handleContentChange("commitments")}
          >
            <Badge
              badgeContent={transformedData.commitments.items.length}
              color="primary"
            >
              <Lock color={theme.palette.text.primary} size={20} />
            </Badge>
          </IconButton>

          <IconButton
            sx={{ color: theme.palette.neutral.alternative }}
            onClick={() => handleContentChange("objections")}
          >
            <Badge
              badgeContent={transformedData.objections.items.length}
              color="primary"
            >
              <WarningCircle color={theme.palette.text.primary} size={20} />
            </Badge>
          </IconButton>
          <IconButton
            sx={{ color: theme.palette.neutral.alternative }}
            onClick={() => handleContentChange("painPoints")}
          >
            <Badge
              badgeContent={transformedData.painPoints.items.length}
              color="primary"
            >
              <SmileySad color={theme.palette.text.primary} size={20} />
            </Badge>
          </IconButton>
        </Box>
        <Typography variant="h6">{content.title}</Typography>
      </Stack>

      <Box height="100px" overflow={"auto"}>
        <List sx={{ mx: 2 }}>
          {content.items.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                border: "1px solid",
                borderRadius: "20px",
                borderColor: theme.palette.divider,
                margin: theme.spacing(1),
                px: theme.spacing(2),
                py: theme.spacing(0.7),
              }}
            >
              <Typography variant="body2">{item}</Typography>
              {/* <ListItemText primary={item} /> */}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Acop;

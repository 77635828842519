import React from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import WidgetBox from "../../components/WidgetBox";
// import FileUploader from "../../components/FileUploader";
import FileUploader from "../../components/file_upload/FileUploader";
import CallList from "../../components/CallList";
import { mockCalls } from "../../data/data";
import { ArrowClockwise } from "@phosphor-icons/react";
import { useGetCallsQuery } from "../../state/api";

const Upload = () => {
  const theme = useTheme();
  const {
    data: fetchedCalls = [],
    error,
    isLoading,
    refetch,
  } = useGetCallsQuery({
    status: "processing",
  });

  const handleUploadComplete = () => {
    refetch();
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)", // 12 columns
        gridTemplateRows: "repeat(13, 1fr)", // 8 rows
        gap: theme.spacing(0.8), // spacing between grid items
        padding: 0,
        height: "100%",
      }}
    >
      <WidgetBox
        background="transparent"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          padding: 0,
          borderRadius: 0,
          gridColumn: "span 12",
          gridRow: "span 1",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Stack paddingBottom={theme.spacing(1)} direction={"column"}>
          <Typography variant="h1" color="textPrimary">
            Upload Files
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Upload new files for processing
          </Typography>
        </Stack>
      </WidgetBox>

      <WidgetBox
        background={theme.palette.neutral.black}
        sx={{
          gridColumn: "span 6",
          gridRow: "span 7",
          border: `2px solid ${
            theme.palette.mode === "dark"
              ? theme.palette.background.widget
              : theme.palette.background.default
          }`,
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h2" color={theme.palette.neutral.white}>
            Manual file upload
          </Typography>
        </Box>
        <FileUploader onUploadComplete={handleUploadComplete}></FileUploader>
      </WidgetBox>

      <WidgetBox
        background={theme.palette.neutral.main}
        sx={{
          gridColumn: "span 6",
          gridRow: "span 12",
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h2">Processing Queue</Typography>
          <IconButton onClick={refetch}>
            <ArrowClockwise size={20} />
          </IconButton>
        </Box>

        <CallList calls={fetchedCalls} status={"processing"}></CallList>
      </WidgetBox>

      <WidgetBox
        background={theme.palette.neutral.main}
        sx={{
          gridColumn: "span 6",
          gridRow: "span 5",
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h2">Integrations</Typography>
        </Box>
      </WidgetBox>
    </Box>
  );
};

export default Upload;

// mockData.js
import { faker } from "@faker-js/faker";

export const callHeaders = [
  "File Name",
  "Agent",
  "Language",
  "Duration",
  "Date",
  "Completeness",
  "AI Score",
];

export const agentLeaderBoard = [
  {
    name: "John Martin",
    branch: "Alphas",
    image: "path_to_image_1",
    score: 0.87,
  },
  { name: "Mary Jane", branch: "Betas", image: "path_to_image_2", score: 0.84 },
  {
    name: "Fernando Mendoza",
    branch: "Gammas",
    image: "path_to_image_3",
    score: 0.83,
  },
];

export const indicators = [
  {
    title: "Total Sales",
    mainText: "$5000",
    description: "This represents the total sales made this month.",
  },
  {
    title: "Customer Calls",
    mainText: "15 calls",
    description: "Number of customer support calls handled by the team.",
  },
  {
    title: "New Subscribers",
    mainText: "200",
    description:
      "Count of new subscribers added to the mailing list this week.",
  },
  {
    title: "Revenue",
    mainText: "$75000",
    description:
      "Total revenue generated from all sources over the last quarter.",
  },
  {
    title: "Completed Orders",
    mainText: "120 orders",
    description: "Number of orders.",
  },
];

export const chartData = [
  {
    id: "lineData",
    data: [
      { x: "point 1", y: 3 },
      { x: "point 2", y: 6 },
      { x: "point 3", y: 12 },
      { x: "point 4", y: 9 },
      { x: "point 5", y: 7 },
      { x: "point 6", y: 3 },
      { x: "point 7", y: 4 },
      { x: "point 8", y: 11 },
      { x: "point 9", y: 4 },
      { x: "point 10", y: 7 },
    ],
  },
];

export const stages = [
  { name: "Greeting", score: 8 },
  { name: "Product Presentation", score: 5 },
  { name: "Handling Objections", score: 7 },
  { name: "Closing", score: 9 },
];

export const summaryData =
  "The agent greeted the client and asked how they could assist. The client mentioned having trouble with their account login. The agent then requested the client's account email to further assist with the issue.";

export const products = [
  { name: "Product A" },
  { name: "Product B" },
  { name: "Product E longer description" },
  { name: "Product C" },
  { name: "Product D" },
  { name: "Product D shorter" },
];

export const targets = [
  { name: "Deposit" },
  { name: "Sale" },
  { name: "Meeting" },
  { name: "Follow-up" },
];

export const profiledata = {
  Name: "John Doe",
  Age: "30",
  Experience: "5 years",
  Income: "$70,000",
  Location: "New York, USA",
  Occupation: "Software Engineer",
  Education: "Bachelor's Degree",
  "Marital Status": "Single",
};

export const acopData = {
  agreements: {
    title: "Agreements",
    items: ["Signed contract with ABC Corp", "Agreed on project scope"],
  },
  commitments: {
    title: "Commitments",
    items: ["Project delivery by Q3", "Weekly status updates"],
  },
  objections: {
    title: "Objections",
    items: ["Concern about pricing", "Question about delivery timeline"],
  },
  painPoints: {
    title: "Pain Points",
    items: [
      "Integration with existing systems",
      "Training requirements for staff",
    ],
  },
};

export const metricsData = [
  { title: "Agent/Client", value: "65%" },
  { title: "Silence Duration", value: "15s" },
  { title: "Closing Attempts", value: "3" },
  { title: "Client Engagement", value: "75%" },
];

export const mockCalls = Array.from({ length: 10 }).map(() => ({
  agent: {
    avatar: faker.image.avatar(),
    name: faker.person.fullName(),
    branch: faker.location.city(),
  },
  language: faker.helpers.arrayElement(["English", "Spanish", "German"]),
  duration: `${faker.number.int({ min: 1, max: 60 })} mins`,
  date: faker.date.past().toLocaleDateString(),
  completeness: `${faker.number.int({ min: 80, max: 100 })}%`,
  aiScore: faker.number.float({ min: 10, max: 100, precision: 0.25 }),
}));

// export const stagesSettings = [
//   {
//     id: 1,
//     name: "Stage 1",
//     description: "This is the description for stage 1.",
//     keywords: ["keyword1", "keyword2", "keyword3"],
//   },
//   {
//     id: 2,
//     name: "Stage 2",
//     description: "This is the description for stage 2.",
//     keywords: ["keyword4", "keyword5"],
//   },
// ];

// export const productsSettings = [
//   {
//     id: 1,
//     name: "Ultra HD TV",
//     description:
//       "A 55-inch Ultra HD television with crystal clear picture quality and smart features.",
//     keywords: ["electronics", "television", "4K", "smart TV"],
//   },
//   {
//     id: 2,
//     name: "Wireless Headphones",
//     description:
//       "Noise-cancelling wireless headphones with high-fidelity sound and long battery life.",
//     keywords: ["electronics", "audio", "headphones", "wireless"],
//   },
//   {
//     id: 3,
//     name: "Smartphone",
//     description:
//       "Latest model smartphone with a high-resolution camera, fast processor, and large display.",
//     keywords: ["electronics", "phone", "mobile", "camera"],
//   },
//   {
//     id: 4,
//     name: "Electric Kettle",
//     description:
//       "1.7-liter electric kettle with auto shut-off and boil-dry protection.",
//     keywords: ["kitchen", "appliance", "kettle", "electric"],
//   },
//   {
//     id: 5,
//     name: "Gaming Laptop",
//     description:
//       "High-performance gaming laptop with powerful graphics, fast processor, and ample storage.",
//     keywords: ["electronics", "laptop", "gaming", "computer"],
//   },
// ];

// export const flagsSettings = [
//   {
//     id: 1,
//     name: "Overpromise",
//     description:
//       "Phrases that indicate making promises or guarantees that cannot be assured.",
//     stopwords: ["guarantee", "promise", "definitely", "absolutely"],
//   },
//   {
//     id: 2,
//     name: "Negative Language",
//     description: "Words or phrases that convey negativity or discouragement.",
//     stopwords: ["can't", "won't", "impossible", "never"],
//   },
//   {
//     id: 3,
//     name: "Unprofessional Language",
//     description:
//       "Words or phrases that are considered unprofessional or inappropriate.",
//     stopwords: [
//       "damn",
//       "hell",
//       "stupid",
//       "idiot",
//       "motherfucker",
//       "hate",
//       "fucking",
//     ],
//   },
//   {
//     id: 4,
//     name: "Vague Commitments",
//     description: "Phrases that indicate a lack of commitment or certainty.",
//     stopwords: ["maybe", "might", "possibly", "we'll see"],
//   },
//   {
//     id: 5,
//     name: "High Pressure Sales",
//     description: "Phrases that indicate high pressure or pushy sales tactics.",
//     stopwords: ["buy now", "limited time", "act fast", "last chance"],
//   },
// ];

// settingsData.js
export const settingsData = {
  Stages: [
    {
      id: 1,
      name: "Stage 1",
      description: "This is the description for stage 1.",
      keywords: ["keyword1", "keyword2", "keyword3"],
    },
    {
      id: 2,
      name: "Stage 2",
      description: "This is the description for stage 2.",
      keywords: ["keyword4", "keyword5"],
    },
  ],
  Products: [
    {
      id: 1,
      name: "Ultra HD TV",
      description:
        "A 55-inch Ultra HD television with crystal clear picture quality and smart features.",
      keywords: ["electronics", "television", "4K", "smart TV"],
    },
    {
      id: 2,
      name: "Wireless Headphones",
      description:
        "Noise-cancelling wireless headphones with high-fidelity sound and long battery life.",
      keywords: ["electronics", "audio", "headphones", "wireless"],
    },
    {
      id: 3,
      name: "Smartphone",
      description:
        "Latest model smartphone with a high-resolution camera, fast processor, and large display.",
      keywords: ["electronics", "phone", "mobile", "camera"],
    },
  ],
  Profiling: [
    {
      id: 1,
      name: "Profile 1",
      items: ["item1", "item2", "item3"],
    },
    {
      id: 2,
      name: "Profile 2",
      items: ["item4", "item5"],
    },
  ],
};

// fieldConfigs.js
// export const fieldConfigs = {
//   Stages: [
//     { name: "name", label: "Name", type: "text" },
//     { name: "description", label: "Description", type: "text" },
//     { name: "keywords", label: "Keywords", type: "array" }
//   ],
//   Products: [
//     { name: "name", label: "Name", type: "text" },
//     { name: "description", label: "Description", type: "text" },
//     { name: "keywords", label: "Keywords", type: "array" }
//   ],
//   Profiling: [
//     { name: "name", label: "Name", type: "text" },
//     { name: "items", label: "Items", type: "array" }
//   ]
// };

export const transcriptionData = [
  {
    speaker: "0",
    words: [
      { text: "Hola", start_time: 1.61, end_time: 1.87 },
      { text: "chicos", start_time: 1.879, end_time: 2.39 },
      { text: "Bienvenidos", start_time: 2.589, end_time: 3.5 },
      { text: "a", start_time: 3.73, end_time: 3.829 },
      { text: "nuestros", start_time: 3.839, end_time: 4.269 },
      { text: "diálogos", start_time: 4.28, end_time: 4.96 },
      { text: "de", start_time: 4.969, end_time: 5.05 },
      { text: "práctica", start_time: 5.059, end_time: 5.59 },
      { text: "para", start_time: 5.599, end_time: 5.869 },
      { text: "Call", start_time: 5.88, end_time: 6.15 },
      { text: "Center", start_time: 6.159, end_time: 6.65 },
      { text: "Hoy", start_time: 7.719, end_time: 7.96 },
      { text: "vamos", start_time: 7.969, end_time: 8.3 },
      { text: "a", start_time: 8.31, end_time: 8.319 },
      { text: "hablar", start_time: 8.329, end_time: 8.84 },
      { text: "sobre", start_time: 8.85, end_time: 9.3 },
      { text: "asistencia", start_time: 9.31, end_time: 10.06 },
      { text: "financiera", start_time: 10.069, end_time: 10.899 },
      { text: "para", start_time: 10.909, end_time: 11.239 },
      { text: "tarjetas", start_time: 11.25, end_time: 11.93 },
      { text: "de", start_time: 11.939, end_time: 12.02 },
      { text: "crédito", start_time: 12.029, end_time: 12.439 },
      { text: "Buenos", start_time: 15.68, end_time: 16.09 },
      { text: "días", start_time: 16.1, end_time: 16.469 },
      { text: "Gracias", start_time: 16.7, end_time: 17.209 },
      { text: "por", start_time: 17.219, end_time: 17.37 },
      { text: "llamar", start_time: 17.379, end_time: 17.77 },
      { text: "a", start_time: 17.78, end_time: 17.879 },
      { text: "Banco", start_time: 17.889, end_time: 18.25 },
      { text: "Virtual", start_time: 18.26, end_time: 18.86 },
      { text: "Mi", start_time: 18.87, end_time: 19.049 },
      { text: "nombre", start_time: 19.059, end_time: 19.389 },
      { text: "es", start_time: 19.399, end_time: 19.53 },
      { text: "Miguel", start_time: 19.54, end_time: 19.959 },
      { text: "Cómo", start_time: 20.129, end_time: 20.42 },
      { text: "puedo", start_time: 20.43, end_time: 20.649 },
      { text: "ayudarle", start_time: 20.659, end_time: 21.129 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Sí", start_time: 21.67, end_time: 21.93 },
      { text: "por", start_time: 21.94, end_time: 22.11 },
      { text: "favor", start_time: 22.12, end_time: 22.569 },
      { text: "Le", start_time: 22.969, end_time: 23.12 },
      { text: "llamo", start_time: 23.129, end_time: 23.409 },
      { text: "por", start_time: 23.42, end_time: 23.59 },
      { text: "caso", start_time: 23.6, end_time: 23.889 },
      { text: "de", start_time: 23.899, end_time: 23.93 },
      { text: "urgencia", start_time: 23.94, end_time: 24.579 },
      { text: "He", start_time: 25.129, end_time: 25.239 },
      { text: "perdido", start_time: 25.25, end_time: 25.659 },
      { text: "mi", start_time: 25.67, end_time: 25.76 },
      { text: "tarjeta", start_time: 25.77, end_time: 26.25 },
      { text: "de", start_time: 26.26, end_time: 26.329 },
      { text: "crédito", start_time: 26.34, end_time: 26.829 },
      { text: "y", start_time: 26.84, end_time: 26.94 },
      { text: "necesito", start_time: 26.95, end_time: 27.51 },
      { text: "asistencia", start_time: 27.52, end_time: 28.27 },
      { text: "Sí", start_time: 28.34, end_time: 28.709 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "No", start_time: 28.78, end_time: 28.989 },
      { text: "se", start_time: 29.0, end_time: 29.129 },
      { text: "preocupe", start_time: 29.139, end_time: 29.68 },
      { text: "Vamos", start_time: 29.7, end_time: 30.12 },
      { text: "a", start_time: 30.129, end_time: 30.2 },
      { text: "intentar", start_time: 30.209, end_time: 30.69 },
      { text: "ayudarla", start_time: 30.7, end_time: 31.329 },
      { text: "Podría", start_time: 31.579, end_time: 32.06 },
      { text: "usted", start_time: 32.069, end_time: 32.389 },
      { text: "darme", start_time: 32.4, end_time: 32.849 },
      { text: "su", start_time: 32.86, end_time: 33.15 },
      { text: "nombre", start_time: 33.159, end_time: 33.529 },
      { text: "completo", start_time: 33.54, end_time: 34.169 },
      { text: "y", start_time: 34.33, end_time: 34.52 },
      { text: "los", start_time: 34.529, end_time: 34.77 },
      { text: "cuatro", start_time: 34.779, end_time: 35.169 },
      { text: "últimos", start_time: 35.18, end_time: 35.689 },
      { text: "dígitos", start_time: 35.7, end_time: 36.29 },
      { text: "del", start_time: 36.299, end_time: 36.65 },
      { text: "número", start_time: 36.659, end_time: 37.099 },
      { text: "de", start_time: 37.11, end_time: 37.189 },
      { text: "su", start_time: 37.2, end_time: 37.33 },
      { text: "seguridad", start_time: 37.34, end_time: 37.849 },
      { text: "social", start_time: 37.86, end_time: 38.369 },
      { text: "por", start_time: 38.54, end_time: 38.709 },
      { text: "favor", start_time: 38.72, end_time: 39.049 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Sí", start_time: 39.479, end_time: 39.93 },
      { text: "mi", start_time: 40.77, end_time: 40.93 },
      { text: "nombre", start_time: 40.939, end_time: 41.409 },
      { text: "es", start_time: 41.419, end_time: 41.61 },
      { text: "Cristina", start_time: 41.65, end_time: 42.18 },
      { text: "González", start_time: 42.189, end_time: 42.849 },
      { text: "Y", start_time: 43.04, end_time: 43.159 },
      { text: "los", start_time: 43.169, end_time: 43.33 },
      { text: "cuatro", start_time: 43.34, end_time: 43.65 },
      { text: "últimos", start_time: 43.659, end_time: 44.049 },
      { text: "dígitos", start_time: 44.06, end_time: 44.59 },
      { text: "son", start_time: 44.599, end_time: 44.86 },
      { text: "tres", start_time: 45.47, end_time: 45.959 },
      { text: "nueve", start_time: 45.97, end_time: 46.439 },
      { text: "seis", start_time: 46.639, end_time: 47.24 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "uno", start_time: 47.259, end_time: 47.759 },
      { text: "Y", start_time: 48.15, end_time: 48.27 },
      { text: "por", start_time: 48.279, end_time: 48.439 },
      { text: "último", start_time: 48.45, end_time: 48.88 },
      { text: "necesito", start_time: 48.889, end_time: 49.54 },
      { text: "que", start_time: 49.549, end_time: 49.65 },
      { text: "me", start_time: 49.659, end_time: 49.849 },
      { text: "facilite", start_time: 49.86, end_time: 50.479 },
      { text: "también", start_time: 50.49, end_time: 51.159 },
      { text: "su", start_time: 51.18, end_time: 51.799 },
      { text: "fecha", start_time: 51.81, end_time: 52.2 },
      { text: "de", start_time: 52.209, end_time: 52.299 },
      { text: "nacimiento", start_time: 52.31, end_time: 52.919 },
      { text: "por", start_time: 52.93, end_time: 53.09 },
      { text: "favor", start_time: 53.099, end_time: 53.439 },
      { text: "Sí", start_time: 54.119, end_time: 54.4 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "por", start_time: 54.409, end_time: 54.58 },
      { text: "supuesto", start_time: 54.59, end_time: 55.2 },
      { text: "Mi", start_time: 55.439, end_time: 55.63 },
      { text: "fecha", start_time: 55.639, end_time: 55.959 },
      { text: "de", start_time: 55.97, end_time: 56.04 },
      { text: "nacimiento", start_time: 56.049, end_time: 56.959 },
      { text: "es", start_time: 57.04, end_time: 57.459 },
      { text: "el", start_time: 57.47, end_time: 57.669 },
      { text: "cinco", start_time: 57.68, end_time: 58.08 },
      { text: "de", start_time: 58.09, end_time: 58.2 },
      { text: "diciembre", start_time: 58.209, end_time: 58.849 },
      { text: "de", start_time: 58.86, end_time: 59.08 },
      { text: "mil", start_time: 59.09, end_time: 59.4 },
      { text: "novecientos", start_time: 59.409, end_time: 60.119 },
      { text: "noventa", start_time: 60.13, end_time: 60.619 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "Gracias", start_time: 61.069, end_time: 61.56 },
      { text: "por", start_time: 61.569, end_time: 61.799 },
      { text: "verificar", start_time: 61.81, end_time: 62.319 },
      { text: "sus", start_time: 62.33, end_time: 62.56 },
      { text: "datos", start_time: 62.569, end_time: 62.919 },
      { text: "personales", start_time: 62.93, end_time: 63.799 },
      { text: "y", start_time: 63.81, end_time: 64.04 },
      { text: "ayudarnos", start_time: 64.05, end_time: 64.72 },
      { text: "a", start_time: 64.73, end_time: 64.76 },
      { text: "mantener", start_time: 64.769, end_time: 65.279 },
      { text: "su", start_time: 65.29, end_time: 65.48 },
      { text: "cuenta", start_time: 65.489, end_time: 65.86 },
      { text: "segura", start_time: 65.87, end_time: 66.279 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Es", start_time: 66.62, end_time: 66.779 },
      { text: "posible", start_time: 66.79, end_time: 67.339 },
      { text: "que", start_time: 67.349, end_time: 67.5 },
      { text: "me", start_time: 67.51, end_time: 67.68 },
      { text: "dé", start_time: 67.69, end_time: 67.97 },
      { text: "una", start_time: 68.16, end_time: 68.389 },
      { text: "nueva", start_time: 68.4, end_time: 68.65 },
      { text: "tarjeta", start_time: 68.66, end_time: 69.16 },
      { text: "de", start_time: 69.169, end_time: 69.279 },
      { text: "crédito", start_time: 69.29, end_time: 69.8 },
      { text: "en", start_time: 69.959, end_time: 70.12 },
      { text: "este", start_time: 70.129, end_time: 70.36 },
      { text: "momento", start_time: 70.37, end_time: 70.87 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "Sí", start_time: 71.449, end_time: 71.839 },
      { text: "voy", start_time: 72.139, end_time: 72.48 },
      { text: "a", start_time: 72.489, end_time: 72.519 },
      { text: "solicitarle", start_time: 72.529, end_time: 73.47 },
      { text: "una", start_time: 73.489, end_time: 73.769 },
      { text: "nueva", start_time: 73.779, end_time: 74.04 },
      { text: "tarjeta", start_time: 74.05, end_time: 74.51 },
      { text: "de", start_time: 74.519, end_time: 74.599 },
      { text: "crédito", start_time: 74.61, end_time: 75.129 },
      { text: "Pero", start_time: 75.519, end_time: 75.83 },
      { text: "primero", start_time: 75.839, end_time: 76.379 },
      { text: "tenemos", start_time: 76.389, end_time: 76.87 },
      { text: "que", start_time: 76.879, end_time: 77.11 },
      { text: "bloquear", start_time: 77.12, end_time: 77.739 },
      { text: "y", start_time: 77.75, end_time: 77.949 },
      { text: "cancelar", start_time: 77.959, end_time: 78.589 },
      { text: "la", start_time: 78.599, end_time: 78.86 },
      { text: "tarjeta", start_time: 78.87, end_time: 79.43 },
      { text: "actual", start_time: 79.44, end_time: 79.849 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Muy", start_time: 80.48, end_time: 80.73 },
      { text: "bien", start_time: 80.739, end_time: 81.11 },
      { text: "Pues", start_time: 81.18, end_time: 81.449 },
      { text: "muchas", start_time: 81.459, end_time: 81.79 },
      { text: "gracias", start_time: 81.8, end_time: 82.349 },
      { text: "Cuántos", start_time: 82.75, end_time: 83.169 },
      { text: "minutos", start_time: 83.18, end_time: 83.569 },
      { text: "necesita", start_time: 83.58, end_time: 84.25 },
      { text: "Es", start_time: 84.62, end_time: 84.809 },
      { text: "posible", start_time: 84.819, end_time: 85.209 },
      { text: "hacerlo", start_time: 85.22, end_time: 85.65 },
      { text: "durante", start_time: 85.66, end_time: 86.019 },
      { text: "la", start_time: 86.029, end_time: 86.129 },
      { text: "mañana", start_time: 86.139, end_time: 86.69 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "Sí", start_time: 87.29, end_time: 87.72 },
      { text: "no", start_time: 87.819, end_time: 88.059 },
      { text: "se", start_time: 88.069, end_time: 88.18 },
      { text: "preocupe", start_time: 88.19, end_time: 88.69 },
      { text: "Solamente", start_time: 88.9, end_time: 89.739 },
      { text: "nos", start_time: 89.75, end_time: 90.05 },
      { text: "va", start_time: 90.059, end_time: 90.169 },
      { text: "a", start_time: 90.18, end_time: 90.279 },
      { text: "llevar", start_time: 90.29, end_time: 90.73 },
      { text: "dos", start_time: 90.739, end_time: 90.97 },
      { text: "o", start_time: 90.98, end_time: 91.059 },
      { text: "tres", start_time: 91.069, end_time: 91.3 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "minutos", start_time: 91.309, end_time: 91.73 },
      { text: "Muchísimas", start_time: 91.889, end_time: 92.529 },
      { text: "gracias", start_time: 92.54, end_time: 93.089 },
      { text: "Me", start_time: 93.62, end_time: 93.769 },
      { text: "quedo", start_time: 93.779, end_time: 93.97 },
      { text: "en", start_time: 93.98, end_time: 94.04 },
      { text: "espera", start_time: 94.05, end_time: 94.489 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "Ya", start_time: 94.819, end_time: 95.069 },
      { text: "casi", start_time: 95.08, end_time: 95.36 },
      { text: "hemos", start_time: 95.37, end_time: 95.54 },
      { text: "terminado", start_time: 95.55, end_time: 96.05 },
      { text: "Permítame", start_time: 96.12, end_time: 96.809 },
      { text: "un", start_time: 96.819, end_time: 96.879 },
      { text: "minuto", start_time: 96.889, end_time: 97.309 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Sí", start_time: 97.769, end_time: 98.19 },
      { text: "Gracias", start_time: 98.199, end_time: 98.959 },
      { text: "Me", start_time: 99.559, end_time: 99.709 },
      { text: "mantengo", start_time: 99.72, end_time: 100.12 },
      { text: "a", start_time: 100.129, end_time: 100.16 },
      { text: "la", start_time: 100.169, end_time: 100.199 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "espera", start_time: 100.209, end_time: 100.61 },
      { text: "A", start_time: 100.839, end_time: 100.97 },
      { text: "continuación", start_time: 100.98, end_time: 101.76 },
      { text: "le", start_time: 101.9, end_time: 102.209 },
      { text: "vamos", start_time: 102.22, end_time: 102.559 },
      { text: "a", start_time: 102.569, end_time: 102.68 },
      { text: "enviar", start_time: 102.69, end_time: 103.16 },
      { text: "un", start_time: 103.169, end_time: 103.37 },
      { text: "correo", start_time: 103.379, end_time: 103.72 },
      { text: "electrónico", start_time: 103.73, end_time: 104.43 },
      { text: "con", start_time: 104.44, end_time: 104.699 },
      { text: "instrucciones", start_time: 104.709, end_time: 105.55 },
      { text: "para", start_time: 105.559, end_time: 105.76 },
      { text: "activar", start_time: 105.769, end_time: 106.18 },
      { text: "su", start_time: 106.19, end_time: 106.379 },
      { text: "nueva", start_time: 106.389, end_time: 106.699 },
      { text: "tarjeta", start_time: 106.709, end_time: 107.199 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Sí", start_time: 107.51, end_time: 107.76 },
      { text: "por", start_time: 107.769, end_time: 107.94 },
      { text: "favor", start_time: 107.949, end_time: 108.379 },
      { text: "Mi", start_time: 108.9, end_time: 109.069 },
      { text: "correo", start_time: 109.08, end_time: 109.419 },
      { text: "es", start_time: 109.43, end_time: 109.519 },
      { text: "el", start_time: 109.529, end_time: 109.639 },
      { text: "mismo", start_time: 109.65, end_time: 110.0 },
      { text: "que", start_time: 110.01, end_time: 110.08 },
      { text: "estaba", start_time: 110.089, end_time: 110.389 },
      { text: "indicado", start_time: 110.4, end_time: 110.839 },
      { text: "en", start_time: 110.849, end_time: 110.91 },
      { text: "cuenta", start_time: 110.919, end_time: 111.309 },
      { text: "Cristina", start_time: 111.989, end_time: 112.62 },
      { text: "Gozi", start_time: 112.629, end_time: 113.169 },
      { text: "arroba", start_time: 113.5, end_time: 114.04 },
      { text: "hotmail", start_time: 114.33, end_time: 114.989 },
      { text: "dot", start_time: 115.0, end_time: 115.3 },
      { text: "Com", start_time: 115.309, end_time: 115.66 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "Sí", start_time: 121.05, end_time: 121.459 },
      { text: "lo", start_time: 121.55, end_time: 121.76 },
      { text: "tenemos", start_time: 121.769, end_time: 122.16 },
      { text: "aquí", start_time: 122.169, end_time: 122.449 },
      { text: "señorita", start_time: 122.459, end_time: 123.0 },
      { text: "Cristina", start_time: 123.01, end_time: 123.519 },
      { text: "Es", start_time: 123.819, end_time: 124.04 },
      { text: "correcto", start_time: 124.05, end_time: 124.599 },
      { text: "Acaba", start_time: 124.91, end_time: 125.319 },
      { text: "de", start_time: 125.33, end_time: 125.41 },
      { text: "ser", start_time: 125.419, end_time: 125.599 },
      { text: "enviado", start_time: 125.61, end_time: 126.11 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Déjeme", start_time: 126.55, end_time: 127.01 },
      { text: "que", start_time: 127.019, end_time: 127.12 },
      { text: "verifico", start_time: 127.129, end_time: 127.699 },
      { text: "un", start_time: 128.199, end_time: 128.339 },
      { text: "segundo", start_time: 128.35, end_time: 128.72 },
      { text: "por", start_time: 128.729, end_time: 128.869 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "favor", start_time: 128.88, end_time: 129.24 },
      { text: "Por", start_time: 129.509, end_time: 129.72 },
      { text: "supuesto", start_time: 129.729, end_time: 130.339 },
      { text: "Tómese", start_time: 130.429, end_time: 130.919 },
      { text: "su", start_time: 130.929, end_time: 131.08 },
      { text: "tiempo", start_time: 131.089, end_time: 131.449 },
      { text: "aquí", start_time: 131.72, end_time: 132.0 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Lo", start_time: 132.009, end_time: 132.119 },
      { text: "tengo", start_time: 132.13, end_time: 132.52 },
      { text: "Me", start_time: 132.75, end_time: 132.889 },
      { text: "acaba", start_time: 132.899, end_time: 133.199 },
      { text: "de", start_time: 133.21, end_time: 133.279 },
      { text: "llegar", start_time: 133.289, end_time: 133.71 },
      { text: "Muchas", start_time: 134.199, end_time: 134.66 },
      { text: "gracias", start_time: 134.669, end_time: 135.16 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "Ha", start_time: 135.55, end_time: 135.69 },
      { text: "sido", start_time: 135.699, end_time: 135.96 },
      { text: "un", start_time: 135.97, end_time: 136.05 },
      { text: "placer", start_time: 136.059, end_time: 136.47 },
      { text: "Tan", start_time: 137.059, end_time: 137.3 },
      { text: "sólo", start_time: 137.309, end_time: 137.559 },
      { text: "tiene", start_time: 137.57, end_time: 137.8 },
      { text: "que", start_time: 137.809, end_time: 137.889 },
      { text: "seguir", start_time: 137.899, end_time: 138.24 },
      { text: "las", start_time: 138.25, end_time: 138.49 },
      { text: "instrucciones", start_time: 138.5, end_time: 139.399 },
      { text: "y", start_time: 139.47, end_time: 139.72 },
      { text: "recibirá", start_time: 139.729, end_time: 140.36 },
      { text: "en", start_time: 140.369, end_time: 140.479 },
      { text: "un", start_time: 140.49, end_time: 140.66 },
      { text: "segundo", start_time: 140.669, end_time: 141.25 },
      { text: "correo", start_time: 141.259, end_time: 141.619 },
      { text: "electrónico", start_time: 141.63, end_time: 142.399 },
      { text: "su", start_time: 142.949, end_time: 143.24 },
      { text: "contraseña", start_time: 143.25, end_time: 144.089 },
      { text: "temporal", start_time: 144.1, end_time: 144.6 },
      { text: "de", start_time: 144.61, end_time: 144.679 },
      { text: "acceso", start_time: 144.69, end_time: 145.1 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Entiendo", start_time: 146.419, end_time: 147.11 },
      { text: "Muchas", start_time: 147.429, end_time: 147.889 },
      { text: "gracias", start_time: 147.899, end_time: 148.449 },
      { text: "Espero", start_time: 148.589, end_time: 148.99 },
      { text: "que", start_time: 149.0, end_time: 149.11 },
      { text: "funcione", start_time: 149.119, end_time: 149.71 },
      { text: "y", start_time: 149.929, end_time: 150.0 },
      { text: "le", start_time: 150.009, end_time: 150.11 },
      { text: "agradezco", start_time: 150.119, end_time: 150.57 },
      { text: "su", start_time: 150.58, end_time: 150.69 },
      { text: "atención", start_time: 150.699, end_time: 151.24 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "Eso", start_time: 151.94, end_time: 152.27 },
      { text: "sí", start_time: 152.279, end_time: 152.55 },
      { text: "No", start_time: 152.82, end_time: 153.13 },
      { text: "se", start_time: 153.139, end_time: 153.25 },
      { text: "olvide", start_time: 153.259, end_time: 153.63 },
      { text: "de", start_time: 153.639, end_time: 153.789 },
      { text: "que", start_time: 153.8, end_time: 154.029 },
      { text: "es", start_time: 154.039, end_time: 154.32 },
      { text: "una", start_time: 154.33, end_time: 154.52 },
      { text: "contraseña", start_time: 154.529, end_time: 155.229 },
      { text: "temporal", start_time: 155.24, end_time: 155.75 },
      { text: "de", start_time: 155.759, end_time: 155.83 },
      { text: "acceso", start_time: 155.839, end_time: 156.27 },
      { text: "Recomendamos", start_time: 156.729, end_time: 157.75 },
      { text: "que", start_time: 157.759, end_time: 157.91 },
      { text: "a", start_time: 157.919, end_time: 157.96 },
      { text: "través", start_time: 157.97, end_time: 158.27 },
      { text: "de", start_time: 158.279, end_time: 158.36 },
      { text: "la", start_time: 158.369, end_time: 158.49 },
      { text: "aplicación", start_time: 158.5, end_time: 159.259 },
      { text: "elija", start_time: 159.369, end_time: 159.889 },
      { text: "una", start_time: 159.899, end_time: 160.1 },
      { text: "nueva", start_time: 160.11, end_time: 160.36 },
      { text: "contraseña", start_time: 160.369, end_time: 161.16 },
      { text: "que", start_time: 161.35, end_time: 161.52 },
      { text: "pueda", start_time: 161.529, end_time: 161.869 },
      { text: "recordar", start_time: 161.88, end_time: 162.38 },
      { text: "fácilmente", start_time: 162.389, end_time: 163.059 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Así", start_time: 163.36, end_time: 163.639 },
      { text: "lo", start_time: 163.649, end_time: 163.759 },
      { text: "haré", start_time: 163.77, end_time: 164.009 },
      { text: "Muchas", start_time: 164.47, end_time: 164.889 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "gracias", start_time: 164.899, end_time: 165.389 },
      { text: "Pues", start_time: 165.759, end_time: 166.029 },
      { text: "ha", start_time: 166.039, end_time: 166.089 },
      { text: "sido", start_time: 166.1, end_time: 166.35 },
      { text: "un", start_time: 166.36, end_time: 166.44 },
      { text: "placer", start_time: 166.449, end_time: 166.789 },
      { text: "el", start_time: 166.8, end_time: 166.94 },
      { text: "atenderle", start_time: 166.949, end_time: 167.55 },
      { text: "Señorita", start_time: 167.559, end_time: 168.07 },
      { text: "Cristina", start_time: 168.08, end_time: 168.589 },
      { text: "Le", start_time: 168.96, end_time: 169.149 },
      { text: "ha", start_time: 169.16, end_time: 169.19 },
      { text: "atendido", start_time: 169.199, end_time: 169.789 },
      { text: "Miguel", start_time: 169.8, end_time: 170.22 },
      { text: "No", start_time: 170.669, end_time: 170.88 },
      { text: "dude", start_time: 170.889, end_time: 171.149 },
      { text: "en", start_time: 171.16, end_time: 171.199 },
      { text: "llamarnos", start_time: 171.21, end_time: 171.83 },
      { text: "si", start_time: 171.839, end_time: 171.91 },
      { text: "es", start_time: 171.919, end_time: 172.029 },
      { text: "que", start_time: 172.039, end_time: 172.11 },
      { text: "tiene", start_time: 172.119, end_time: 172.369 },
      { text: "alguna", start_time: 172.38, end_time: 173.11 },
      { text: "otra", start_time: 173.119, end_time: 173.429 },
      { text: "dificultad", start_time: 173.44, end_time: 174.02 },
    ],
  },
  {
    speaker: "1",
    words: [
      { text: "Gracias", start_time: 174.289, end_time: 174.789 },
      { text: "Nuevamente", start_time: 174.8, end_time: 175.36 },
      { text: "Señor", start_time: 175.369, end_time: 175.669 },
      { text: "Miguel", start_time: 175.679, end_time: 176.029 },
      { text: "ha", start_time: 176.52, end_time: 176.649 },
      { text: "sido", start_time: 176.66, end_time: 176.949 },
      { text: "usted", start_time: 176.96, end_time: 177.22 },
      { text: "muy", start_time: 177.229, end_time: 177.41 },
      { text: "atento", start_time: 177.419, end_time: 177.869 },
    ],
  },
  {
    speaker: "0",
    words: [
      { text: "Muchas", start_time: 178.24, end_time: 178.61 },
      { text: "gracias", start_time: 178.619, end_time: 179.089 },
      { text: "Que", start_time: 179.339, end_time: 179.479 },
      { text: "tenga", start_time: 179.49, end_time: 179.759 },
      { text: "un", start_time: 179.77, end_time: 179.869 },
      { text: "buen", start_time: 179.88, end_time: 180.02 },
      { text: "día", start_time: 180.029, end_time: 180.32 },
      { text: "Si", start_time: 180.839, end_time: 181.07 },
      { text: "te", start_time: 181.08, end_time: 181.19 },
      { text: "ha", start_time: 181.199, end_time: 181.25 },
      { text: "gustado", start_time: 181.259, end_time: 181.86 },
      { text: "suscríbete", start_time: 182.05, end_time: 183.089 },
      { text: "y", start_time: 183.38, end_time: 183.509 },
      { text: "dale", start_time: 183.52, end_time: 183.75 },
      { text: "a", start_time: 183.759, end_time: 183.83 },
      { text: "la", start_time: 183.839, end_time: 183.91 },
      { text: "campanita", start_time: 183.919, end_time: 184.529 },
    ],
  },
];

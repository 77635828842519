import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { themeSettings } from "./theme";
import Dashboard from "./scenes/dashboard";
import Layout from "./scenes/layout";
import Upload from "./scenes/upload";
import AISettings from "./scenes/ai_settings";
import Agents from "./scenes/agents";
import CallDetails from "./scenes/call_details";
import Login from "./scenes/login";
import PrivateRoutes from "./components/utils/PrivateRoutes";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/" element={<PrivateRoutes />}>
              <Route element={<Layout />}>
                <Route
                  path="/"
                  element={<Navigate to="/dashboard" replace />}
                />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/upload" element={<Upload />} />
                <Route path="/agents" element={<Agents />} />
                <Route path="/callDetails" element={<CallDetails />} />
                <Route path="/settings" element={<AISettings />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/auth/login" />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

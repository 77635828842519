import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  showFeedbackReminder:
    JSON.parse(localStorage.getItem("showFeedbackReminder")) !== false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setShowFeedbackReminder: (state, action) => {
      state.showFeedbackReminder = action.payload;
      localStorage.setItem(
        "showFeedbackReminder",
        JSON.stringify(action.payload)
      );
    },
  },
});

export const { setMode, setShowFeedbackReminder } = globalSlice.actions;

export default globalSlice.reducer;

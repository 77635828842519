import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Box,
  useTheme,
  Stack,
} from "@mui/material";
import { useSendFeedbackMutation } from "../state/api";

const FeedbackDialog = ({ open, onClose }) => {
  const theme = useTheme();

  const [feedbackType, setFeedbackType] = React.useState("feedback");
  const [feedbackText, setFeedbackText] = React.useState("");
  const [sendFeedback] = useSendFeedbackMutation();

  const handleTypeChange = (event) => {
    setFeedbackType(event.target.value);
  };

  const handleTextChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await sendFeedback({ type: feedbackType, text: feedbackText }).unwrap();
      onClose();
    } catch (error) {
      console.error("Failed to send feedback:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Leave Feedback</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Feedback Type</InputLabel>
          <Select
            value={feedbackType}
            onChange={handleTypeChange}
            label="Feedback Type"
          >
            <MenuItem value="feedback">Share Feedback</MenuItem>
            <MenuItem value="feature">Request Feature</MenuItem>
            <MenuItem value="question">General Question</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          label="Your Feedback"
          value={feedbackText}
          onChange={handleTextChange}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} gap={2} sx={{ padding: theme.spacing(1) }}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              color: theme.palette.text.primary,
              borderColor: theme.palette.text.primary,
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;

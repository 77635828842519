import {
  Box,
  Typography,
  useTheme,
  Stack,
  CircularProgress,
  Avatar,
  Button,
  Tooltip,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WidgetBox from "../../components/WidgetBox";
import ConversationStages from "../../components/ConversationStages";
import ChipList from "../../components/ChipList";
import ClientProfile from "../../components/ClientProfiling";
import Acop from "../../components/Acop";
import Metric from "../../components/Metric";
import AudioPlayer from "../../components/AudioPlayer";
import MetricCircles from "../../components/MetricCircles";
import SentimentBars from "../../components/SentimentBars";
import CallSelectionBackdrop from "../../components/CallSelectionBackdrop";
import { useGetCallQuery } from "../../state/api";
import { useLocation } from "react-router-dom";
import { CaretLeft, Warning, ListMagnifyingGlass } from "@phosphor-icons/react";
import CustomFab from "../../components/CustomFab";
import { format } from "date-fns";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return format(date, "dd MMM yyyy, HH:mm, EEE");
};

const CallDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const query = useQuery();
  const id = query.get("id");
  const { data = {}, error, isLoading } = useGetCallQuery(id);

  const [callSelectionOpen, setcallSelectionOpen] = useState(false);

  const handleOpenCallSelection = () => {
    setcallSelectionOpen(true);
  };

  const handleCloseCallSelection = () => {
    setcallSelectionOpen(false);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>; // handle the error appropriately
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)", // 12 columns
        gridTemplateRows: "repeat(13, 1fr)", // 8 rows
        gap: theme.spacing(0.8), // spacing between grid items
        padding: 0,
        height: "100%",
      }}
    >
      <WidgetBox
        background="transparent"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          borderRadius: 0,
          gridColumn: "span 12",
          gridRow: "span 1",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Box px={1}>
            <Stack direction="row" gap={1.5}>
              <Tooltip
                TransitionComponent={Zoom}
                placement="bottom"
                title="Back to Dashboard"
                sx={{ color: theme.palette.neutral.white }}
              >
                <CustomFab
                  disableRipple
                  size="small"
                  onClick={() => navigate("/dashboard")}
                  sx={{
                    color: theme.palette.neutral.white,
                    boxShadow: "none !important",
                  }}
                >
                  <CaretLeft size={16} />
                </CustomFab>
              </Tooltip>
              <Tooltip
                TransitionComponent={Zoom}
                placement="bottom"
                title="Select another conversation"
                sx={{ color: theme.palette.neutral.white }}
              >
                <CustomFab
                  disableRipple
                  size="small"
                  onClick={handleOpenCallSelection}
                  sx={{
                    color: theme.palette.neutral.white,
                    boxShadow: "none !important",
                  }}
                >
                  <ListMagnifyingGlass size={16} />
                </CustomFab>
              </Tooltip>
            </Stack>

            {/* <Button
              sx={{
                py: 1,
                height: "100%",
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.neutral.black,
                color: theme.palette.neutral.white,
              }}
              variant="contained"
              startIcon={<ListMagnifyingGlass size={15} />}
              onClick={handleOpenCallSelection}
            >
              Choose Conversation
            </Button> */}
          </Box>

          <Box
            sx={{
              borderRadius: theme.shape.borderRadius,
              border: `2px solid ${theme.palette.background.widget}`,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              mx: theme.spacing(1),
              px: theme.spacing(2),
            }}
          >
            <Box>
              <Avatar
                src={data.agent_details.avatar}
                alt={data.agent_details.name}
                sx={{ width: 30, height: 30, marginRight: 1, marginLeft: -1 }}
              />
            </Box>
            <Stack
              display="flex"
              justifyContent="center"
              alignItems="start"
              flexDirection="column"
            >
              <Typography variant="body3" color="textSecondary" noWrap>
                {data.agent_details.team}
              </Typography>
              <Typography variant="body">{data.agent_details.name}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: theme.shape.borderRadius,
              border: `2px solid ${theme.palette.background.widget}`,
              height: "100%",
              display: "flex",
              alignItems: "center",
              mx: theme.spacing(1),
              px: theme.spacing(2),
            }}
          >
            <Stack direction={"column"}>
              <Typography variant="body3" color="textSecondary">
                Processed on
              </Typography>
              <Typography variant="body">
                {formatTimestamp(data.call.timestamp)}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: theme.shape.borderRadius,
              border: `2px solid ${theme.palette.background.widget}`,
              height: "100%",
              display: "flex",
              alignItems: "center",
              mx: theme.spacing(1),
              px: theme.spacing(2),
            }}
          >
            <Stack direction={"column"}>
              <Typography variant="body3" color="textSecondary">
                Original language
              </Typography>
              <Typography variant="body">{data.call.language}</Typography>
            </Stack>
          </Box>

          {data.call.flagged && (
            <Box
              sx={{
                borderRadius: theme.shape.borderRadius,
                border: `2px solid ${theme.palette.error.main}`,
                height: "100%",
                display: "flex",
                alignItems: "center",
                mx: theme.spacing(1),
                px: theme.spacing(2),
              }}
            >
              <Stack direction={"row"} display={"flex"} alignItems="center">
                <Warning color={theme.palette.error.main} size={16} />
                <Typography
                  variant="body3"
                  color={theme.palette.error.main}
                  sx={{ px: 1 }}
                >
                  This conversation was flagged
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>

        <CallSelectionBackdrop
          open={callSelectionOpen}
          onClose={handleCloseCallSelection}
        />
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 5",
          gridRow: "span 12",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2">Transcription</Typography>
          </Box>
        </Box>
        <Box height="100%" width="100%" overflow="hidden">
          {/* Audio Player */}

          <AudioPlayer
            audioFile={data.call.audio_location}
            transcript={data.call.transcript_content}
            products={data.analytics.products}
            targets={data.analytics.targets}
            flags={[]}
            speechMetrics={data.analytics.speech_metrics}
          />
        </Box>
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 2",
          gridRow: "span 4",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2">Summary</Typography>
          </Box>
        </Box>
        <Box paddingTop={1} height="100%" width="100%" overflow="auto">
          <Typography variant="body2">
            {data.analytics.conversation_summary.summary}
          </Typography>
        </Box>
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 3",
          gridRow: "span 4",
        }}
      >
        <Acop data={data.analytics} />
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 2",
          gridRow: "span 4",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2">Products</Typography>
          </Box>
        </Box>
        <Box py={1} height="100%" width="100%" overflow="hidden">
          <ChipList items={data.analytics.products} random_colors={true} />
        </Box>
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 2",
          gridRow: "span 6",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2">Profiling</Typography>
          </Box>
        </Box>
        <Box height="100%" width="100%" overflow="auto">
          <ClientProfile profileData={data.analytics.profiling.attributes} />
        </Box>
      </WidgetBox>

      <WidgetBox
        // background={`linear-gradient(25deg, ${theme.palette.neutral.main} 65%, ${theme.palette.primary.main} 100%)`}
        sx={{
          gridColumn: "span 2",
          gridRow: "span 4",
        }}
      >
        <MetricCircles
          metrics={{
            time_ratio: data.analytics.speech_metrics.compound_metric,
            silence_duration: data.analytics.silence_duration.silence_seconds,
            closing_attempts: data.analytics.closing_attempts,
            client_engagement: data.analytics.client_engagement,
          }}
        />
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 3",
          gridRow: "span 8",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2">Stages</Typography>
          </Box>
        </Box>
        <Box height="100%" width="100%" overflow="hidden">
          <ConversationStages stages={data.analytics.stages} />
        </Box>
      </WidgetBox>

      <WidgetBox
        // background={theme.palette.primary.dark}
        sx={{
          gridColumn: "span 2",
          gridRow: "span 4",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2">Targets</Typography>
          </Box>
        </Box>
        <Box py={1} height="100%" width="100%" overflow="hidden">
          <ChipList items={data.analytics.targets} />
        </Box>
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 2",
          gridRow: "span 2",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2">Sentiment</Typography>
          </Box>
        </Box>
        <Box marginTop={2} height="100%" width="100%" overflow="hidden">
          <SentimentBars
            weights={[
              data.analytics.sentiment_analysis.negative,
              data.analytics.sentiment_analysis.neutral,
              data.analytics.sentiment_analysis.positive,
            ]}
          />
        </Box>
      </WidgetBox>
    </Box>
  );
};

export default CallDetails;

export const themeSettings = (mode) => ({
  shape: {
    borderRadius: 16,
  },
  palette: {
    mode: mode,
    ...(mode === "dark"
      ? {
          // palette values for dark mode
          primary: {
            main: "#E0FD07",
            light: "#ecfe68",
            dark: "#b3ca02",
            contrastText: "#0F0F0F",
          },
          secondary: {
            main: "#81b7f7",
            light: "#b3d4fa",
            dark: "#6792c6",
          },
          error: {
            main: "#EC173E",
          },
          neutral: {
            black: "#0F0F0F",
            white: "#FFFFFF",
            gray: "#ADADAD",
          },
          background: {
            default: "#0F0F0F",
            widget: "#1D1D1D",
          },
          text: {
            primary: "#FFFFFF",
            secondary: "#858585",
          },
        }
      : {
          // palette values for light mode
          primary: {
            main: "#E0FD07",
            light: "#ecfe68",
            dark: "#b3ca02",
            contrastText: "#0F0F0F",
          },
          secondary: {
            main: "#81b7f7",
            light: "#b3d4fa",
            dark: "#6792c6",
          },
          error: {
            main: "#EC173E",
          },
          neutral: {
            black: "#0F0F0F",
            white: "#FFFFFF",
            gray: "#dadada",
          },
          background: {
            default: "#F5F5F8",
            widget: "#FFFFFF",
            shadow: "",
          },
          text: {
            primary: "#0F0F0F", // customize textPrimary for light mode
            secondary: "#858585",
          },
        }),
  },
  typography: {
    fontFamily: ["Urbanist", "sans-serif"].join(","),
    fontSize: 12,
    h1: {
      fontFamily: ["Urbanist", "sans-serif"].join(","),
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.1,
    },
    h2: {
      fontFamily: ["Urbanist", "sans-serif"].join(","),
      fontSize: 24,
      fontWeight: 500,
    },
    h3: {
      fontFamily: ["Urbanist", "sans-serif"].join(","),
      fontSize: 20,
      fontWeight: 500,
    },
    body: {
      fontFamily: ["Urbanist", "sans-serif"].join(","),
      fontSize: 13,
      lineHeight: 1.4,
      fontWeight: 500,
    },
    body2: {
      fontFamily: ["Urbanist", "sans-serif"].join(","),
      fontSize: 12,
      lineHeight: 1.3,
    },
    body3: {
      fontFamily: ["Urbanist", "sans-serif"].join(","),
      fontSize: 11,
      lineHeight: 1.1,
    },
    body4: {
      fontFamily: ["Urbanist", "sans-serif"].join(","),
      fontSize: 10,
      lineHeight: 1,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Disable text capitalization for all buttons
        },
      },
    },
  },
});

import React, { useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import WidgetBox from "../../components/WidgetBox";
import StagesAccordion from "../../components/accordions/StagesAccordion";
import ProductsAccordion from "../../components/accordions/ProductsAccordion";
import SettingsAccordion from "../../components/accordions/SettingsAccordeon";
import {
  stagesSettings,
  productsSettings,
  flagsSettings,
} from "../../data/data";
import SettingsTabs from "../../components/ai_settings/SettingsTabs";

const AISettings = () => {
  const [expanded, setExpanded] = useState("none");
  const theme = useTheme();

  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const stageFieldConfig = [
    { name: "name", label: "Stage", type: "text" },
    { name: "description", label: "Description", type: "text" },
    { name: "keywords", label: "Keywords", type: "array" },
  ];

  const productFieldConfig = [
    { name: "name", label: "Product", type: "text" },
    { name: "description", label: "Description", type: "text" },
    { name: "keywords", label: "Keywords", type: "array" },
  ];

  const flagFieldConfig = [
    { name: "name", label: "Flag", type: "text" },
    { name: "description", label: "Description", type: "text" },
    { name: "stopwords", label: "Stopwords", type: "array" },
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)", // 12 columns
        gridTemplateRows: "repeat(13, 1fr)", // 8 rows
        gap: theme.spacing(0.8), // spacing between grid items
        padding: 0,
        height: "100%",
      }}
    >
      <WidgetBox
        background="transparent"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          padding: 0,
          borderRadius: 0,
          gridColumn: "span 12",
          gridRow: "span 1",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Stack paddingBottom={theme.spacing(1)} direction={"column"}>
          <Typography variant="h1" color="textPrimary">
            AI configuration
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Customize analytics for your conversations
          </Typography>
        </Stack>
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 12",
          gridRow: "span 12",
          "&::before": {
            content: '""',
            position: "absolute",
            top: "40%",
            left: "100%",
            width: "80%",
            height: "50%",
            transform: "translate(-50%, -50%)",
            background: `radial-gradient(ellipse at center, ${theme.palette.primary.main}, ${theme.palette.background.widget})`,
            filter: "blur(60px)",
            opacity: 0.1,
            zIndex: 0,
          },
        }}
      >
        <SettingsTabs></SettingsTabs>
      </WidgetBox>
    </Box>
  );
};

export default AISettings;

import React, { useState, useEffect } from "react";
import {
  Box,
  Backdrop,
  ClickAwayListener,
  useTheme,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks";
import { useLazyGetTreeViewCallsQuery } from "../state/api"; // Adjust the import path as needed
import { useNavigate } from "react-router-dom";

const CallSelectionBackdrop = ({ open, onClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [contentOpen, setContentOpen] = useState(open);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [loadedNodes, setLoadedNodes] = useState({}); // To keep track of loaded nodes
  const [expandedItems, setExpandedItems] = useState([]); // State to control expanded nodes

  const apiRef = useTreeViewApiRef();
  const [getTreeViewCalls = [], isLoadingTree] = useLazyGetTreeViewCallsQuery();

  useEffect(() => {
    setContentOpen(open);
  }, [open]);

  useEffect(() => {
    // Set default date range to the last week
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );
    setStartDate(lastWeek.toISOString().split("T")[0]);
    setEndDate(today.toISOString().split("T")[0]);
  }, []);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    if (new Date(newStartDate) <= new Date(endDate)) {
      setStartDate(newStartDate);
      setLoadedNodes({});
      setTreeData([]);
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate);
      setLoadedNodes({});
      setTreeData([]);
    }
  };

  useEffect(() => {
    if (!loadedNodes.teams) {
      const fetchInitialData = async () => {
        try {
          const response = await getTreeViewCalls({
            level: "teams-agents",
            startDate,
            endDate,
          }).unwrap();
          console.log("Initial load response:", response);
          setTreeData(
            response.map((team) => ({
              id: team.id,
              label: team.label,
              children: team.children.map((agent) => ({
                id: agent.id,
                label: agent.label,
                children: [],
                level: 1,
              })),
              level: 0,
            }))
          );
          setLoadedNodes((prev) => ({ ...prev, teams: true }));
        } catch (err) {
          console.error("Error loading initial data:", err);
        }
      };
      fetchInitialData();
    }
  }, [getTreeViewCalls, loadedNodes, startDate, endDate]);

  const handleClose = () => {
    setContentOpen(false);
    onClose();
  };

  const handleSelectedItemsChange = async (event, itemId) => {
    if (!itemId || loadedNodes[itemId]) {
      return;
    }

    const item = apiRef.current.getItem(itemId);

    if (item.level === 3) {
      navigate(`/callDetails?id=${item.id}`);
      return;
    }

    if (item && item.children.length === 0) {
      item.children = [
        { id: `${item.id}_loading`, label: "Loading...", children: [] },
      ];
      setTreeData([...treeData]); // Trigger re-render with loading indicator
      setExpandedItems((prevExpanded) => [...prevExpanded, itemId]);
      try {
        let response;
        if (item.level === 0) {
          response = await getTreeViewCalls({
            level: "agents",
            parentId: item.id,
            startDate,
            endDate,
          }).unwrap();
          item.children = response.map((agent) => ({
            id: agent.id,
            label: agent.label,
            children: [],
            level: 1,
          }));
        } else if (item.level === 1) {
          response = await getTreeViewCalls({
            level: "dates",
            parentId: item.id,
            startDate,
            endDate,
          }).unwrap();
          item.children = response.map((date) => ({
            id: date.id,
            label: date.label,
            children: [],
            level: 2,
          }));
        } else if (item.level === 2) {
          const [agentId, selectedDate] = item.id.split("_", 2);
          response = await getTreeViewCalls({
            level: "conversations",
            parentId: agentId,
            startDate,
            endDate,
            selectedDate,
          }).unwrap();
          item.children = response.map((conv) => ({
            id: conv.id,
            label: conv.label,
            level: 3,
          }));
        }
        console.log(treeData);
        setTreeData([...treeData]); // Trigger re-render
        setLoadedNodes((prevLoaded) => ({ ...prevLoaded, [itemId]: true }));
      } catch (err) {
        console.error("Error loading node data:", err);
      }
    }
  };

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  return (
    <>
      <Backdrop
        open={contentOpen}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />
      {contentOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              position: "fixed",
              top: theme.spacing(4),
              left: theme.spacing(4),
              bottom: theme.spacing(4),
              height: `calc(100% - ${theme.spacing(8)})`,
              width: "25%",
              bgcolor: "background.paper",
              boxShadow: 3,
              zIndex: (theme) => theme.zIndex.modal,
              overflow: "auto",
              borderRadius: 2,
              p: 4,
            }}
          >
            <Stack direction={"row"} spacing={2} width={"100%"}>
              <Box width="50%" flexGrow={1}>
                <Typography variant="body3">From</Typography>
                <TextField
                  size="small"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  fullWidth
                />
              </Box>
              <Box width="50%" flexGrow={1}>
                <Typography variant="body3">To</Typography>
                <TextField
                  size="small"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  fullWidth
                />
              </Box>
            </Stack>
            <Box>
              <RichTreeView
                aria-label="call selection"
                // collapseIcon={<span>-</span>}
                // expandIcon={<span>+</span>}
                sx={{ height: "100%", flexGrow: 1, overflowY: "auto", mt: 2 }}
                items={treeData}
                apiRef={apiRef}
                onSelectedItemsChange={handleSelectedItemsChange}
                expandedItems={expandedItems}
                onExpandedItemsChange={handleExpandedItemsChange}
              />
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};

export default CallSelectionBackdrop;

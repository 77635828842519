import React from "react";
import { Box, useTheme } from "@mui/material";

const SentimentBars = ({ weights }) => {
  const theme = useTheme();

  // Ensure weights sum to 1
  const [weight1, weight2, weight3] = weights;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: 30,
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: `${weight1 * 100}%`,
          backgroundColor: "black",
          borderRadius: theme.shape.borderRadius,
          marginRight: "2px", // Add space between bars
        }}
      />
      <Box
        sx={{
          width: `${weight2 * 100}%`,
          backgroundImage:
            "repeating-linear-gradient(90deg, gray, gray 3px, transparent 3px, transparent 6px)",
          // backgroundColor: theme.palette.neutral[200],
          borderRadius: theme.shape.borderRadius,
          marginRight: "2px", // Add space between bars
        }}
      />
      <Box
        sx={{
          width: `${weight3 * 100}%`,
          backgroundColor: theme.palette.primary.main,
          borderRadius: theme.shape.borderRadius,
        }}
      />
    </Box>
  );
};

export default SentimentBars;

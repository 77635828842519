import React from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Gauge = ({ padding, value }) => {
  const theme = useTheme();
  const paddingValue = theme.spacing(padding);
  const size = 180;
  const strokeWidth = 20;
  const radius = (size - strokeWidth) / 2;
  const centerX = size / 2;
  const centerY = radius + strokeWidth / 2;
  const startAngle = -90; // Start from the top
  const primaryEndAngle = startAngle + 180 * (value / 10); // Calculate end angle based on value
  const secondaryStartAngle = primaryEndAngle; // Start of secondary arc
  const secondaryEndAngle = startAngle + 180; // End at 180 degrees
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.primary.dark; // Example secondary color
  const grayColor = theme.palette.grey[400];

  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  const primaryStartCoord = polarToCartesian(
    centerX,
    centerY,
    radius,
    startAngle
  );
  const primaryEndCoord = polarToCartesian(
    centerX,
    centerY,
    radius,
    primaryEndAngle
  );
  const primaryLargeArcFlag = primaryEndAngle - startAngle <= 180 ? "0" : "1";

  const primaryPathData = [
    "M",
    primaryStartCoord.x,
    primaryStartCoord.y,
    "A",
    radius,
    radius,
    0,
    primaryLargeArcFlag,
    1,
    primaryEndCoord.x,
    primaryEndCoord.y,
  ].join(" ");

  const secondaryStartCoord = polarToCartesian(
    centerX,
    centerY,
    radius,
    secondaryStartAngle
  );
  const secondaryEndCoord = polarToCartesian(
    centerX,
    centerY,
    radius,
    secondaryEndAngle
  );
  const secondaryLargeArcFlag =
    secondaryEndAngle - secondaryStartAngle <= 180 ? "0" : "1";

  const secondaryPathData = [
    "M",
    secondaryStartCoord.x,
    secondaryStartCoord.y,
    "A",
    radius,
    radius,
    0,
    secondaryLargeArcFlag,
    1,
    secondaryEndCoord.x,
    secondaryEndCoord.y,
  ].join(" ");

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: paddingValue,
      }}
    >
      <svg
        width={size + 20} // Add extra width
        height={radius + strokeWidth + 20} // Add extra height
        viewBox={`-10 -10 ${size + 20} ${radius + strokeWidth + 20}`} // Adjust viewBox
        style={{ display: "block" }} // Ensure the SVG is block-level to center it correctly
      >
        <defs>
          <linearGradient
            id="primaryGradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{
                stopColor: theme.palette.secondary.main,
                stopOpacity: 1,
              }}
            />
          </linearGradient>
          <linearGradient
            id="secondaryGradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              style={{
                stopColor: theme.palette.neutral.black,
                stopOpacity: 0.5,
              }}
            />
            <stop
              offset="100%"
              style={{
                stopColor: theme.palette.background.widget,
                stopOpacity: 1,
              }}
            />
          </linearGradient>
          <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="6"
              floodColor={theme.palette.primary.main}
              //   floodColor={theme.palette.primary.dark}
              floodOpacity="0.3"
            />
          </filter>
        </defs>
        <path
          d={secondaryPathData}
          fill="none"
          stroke="url(#secondaryGradient)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <path
          d={primaryPathData}
          fill="none"
          stroke="url(#primaryGradient)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          filter="url(#shadow)"
        />
      </svg>
    </Box>
  );
};

Gauge.propTypes = {
  padding: PropTypes.number,
  value: PropTypes.number.isRequired,
};

Gauge.defaultProps = {
  padding: 1,
};

export default Gauge;

import React, { useState } from "react";
import { Box, Tabs, Tab, useTheme } from "@mui/material";
import ListSettings from "./ListSettings";
import GridSettings from "./GridSettings";
import {
  useGetAISettingsQuery,
  useUpdateAISettingsMutation,
} from "../../state/api";
import {
  Steps,
  Cube,
  UserFocus,
  CrosshairSimple,
  Warning,
} from "@phosphor-icons/react";

function SettingsTabs() {
  const theme = useTheme();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { data: settingsData = [], error, isLoading } = useGetAISettingsQuery();
  console.log(settingsData);
  const [updateAISettings] = useUpdateAISettingsMutation();

  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  const handleUpdateSettings = async (field, value) => {
    await updateAISettings({ field, value });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",

        width: "100%",
        height: "100%",
        py: theme.spacing(1),
        px: theme.spacing(0.5),
      }}
    >
      <Tabs
        orientation="vertical"
        value={selectedTabIndex}
        onChange={handleTabChange}
        variant="scrollable"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          height: "100%",
          minWidth: "12%",
        }}
      >
        <Tab
          icon={<Steps size={18} />}
          iconPosition="start"
          label="Stages"
          disableRipple
          sx={{ color: theme.palette.text.primary }}
        />
        <Tab
          icon={<Cube size={18} />}
          iconPosition="start"
          label="Products"
          disableRipple
          sx={{ color: theme.palette.text.primary }}
        />
        <Tab
          icon={<UserFocus size={18} />}
          iconPosition="start"
          label="Profiling"
          disableRipple
          sx={{ color: theme.palette.text.primary }}
        />
        <Tab
          icon={<CrosshairSimple size={18} />}
          iconPosition="start"
          label="Targets"
          disableRipple
          sx={{ color: theme.palette.text.primary }}
        />
        <Tab
          icon={<Warning size={18} />}
          iconPosition="start"
          label="Flags"
          disableRipple
          sx={{ color: theme.palette.error.main }}
        />
      </Tabs>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {selectedTabIndex === 0 && (
          <ListSettings
            title="Stages"
            data={settingsData.stages}
            onUpdate={(value) => handleUpdateSettings("stages", value)}
          />
        )}

        {selectedTabIndex === 1 && (
          <ListSettings
            title="Products"
            data={settingsData.products}
            onUpdate={(value) => handleUpdateSettings("products", value)}
          />
        )}
        {selectedTabIndex === 2 && (
          <GridSettings
            title="Profiling"
            data={settingsData.profiling}
            onUpdate={(value) => handleUpdateSettings("profiling", value)}
          />
        )}
        {selectedTabIndex === 3 && (
          <GridSettings
            title="Targets"
            data={settingsData.targets}
            onUpdate={(value) => handleUpdateSettings("targets", value)}
          />
        )}

        {selectedTabIndex === 4 && (
          <ListSettings
            title="Flags"
            data={settingsData.flags}
            onUpdate={(value) => handleUpdateSettings("flags", value)}
          />
        )}
      </Box>
    </Box>
  );
}

export default SettingsTabs;

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  Popper,
  Paper,
  ClickAwayListener,
  Backdrop,
  Fab,
  Stack,
  getNativeSelectUtilityClasses,
  CircularProgress,
} from "@mui/material";
import WidgetBox from "../../components/WidgetBox";
import { useDispatch, useSelector } from "react-redux";
import LeaderBoard from "../../components/LeaderBoard";
import TextWidget from "../../components/TextWidget";
import LineChart from "../../components/LineChart";
import HorizontalSwitch from "../../components/HorizontalSwitch";
import AnimatedButton from "../../components/AnimatedButton";
import CallList from "../../components/CallList";
import { useTheme } from "@mui/material";
import { Funnel, ArrowUpRight, DownloadSimple } from "@phosphor-icons/react";
import {
  useGetCallsQuery,
  useGetAgentsQuery,
  useGetDashboardDataQuery,
} from "../../state/api";
import { setShowFeedbackReminder } from "../../state";
import { styled } from "@mui/system";
import ChipList from "../../components/ChipList";
import CustomFab from "../../components/CustomFab";
import Gauge from "../../components/Gauge";
import { format } from "date-fns";
import TutorialBackdrop from "../../components/onboarding/TutorialBackdrop";
import FeedbackDialog from "../../components/FeedbackDialog";
import CustomIconButton from "../../components/CustomIconButton";
import SimpleBackdropWithBox from "../../components/onboarding/SimpleBackdropWithBox";

const formatDate = (date) => {
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss");
};

const Dashboard = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const showTutorial = useSelector(
    (state) => state.global.showFeedbackReminder
  );

  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const periods = ["today", "week", "month"];
  const [period, setPeriod] = useState("week");
  const [dataVersion, setDataVersion] = useState(0);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [agentId, setAgentId] = useState(null);
  const [team, setTeam] = useState(null);
  const [status, setStatus] = useState("processed");
  const [language, setLanguage] = useState(null);

  const feedbackButtonRef = useRef(null);
  const [backdropOpen, setBackdropOpen] = useState(showTutorial);

  // Separate state for dialog filters, initialized with current filters
  const [dialogStartDate, setDialogStartDate] = useState(startDate);
  const [dialogEndDate, setDialogEndDate] = useState(endDate);
  const [dialogDateRange, setDialogDateRange] = useState(dateRange);
  const [dialogAgentId, setDialogAgentId] = useState(agentId);
  const [dialogTeam, setDialogTeam] = useState(team);
  const [dialogStatus, setDialogStatus] = useState(status);
  const [dialogLanguage, setDialogLanguage] = useState(language);
  const {
    data: dashboardData = {},
    error: dashboardError,
    isLoading: isLoadingDashboard,
    refetch: refetchDashboard,
  } = useGetDashboardDataQuery(period);

  console.log("Dashboard data", dashboardData);

  const {
    data: fetchedCalls = [],
    error: callsError,
    isLoading: isLoadingCalls,
    refetch: refetchCalls,
  } = useGetCallsQuery({
    status,
    startDate: startDate ? startDate : null,
    endDate: endDate ? endDate : null,
    agentId,
    team,
    language,
  });

  console.log(fetchedCalls);
  const { data: agents = [], isLoading: isLoadingAgents } = useGetAgentsQuery();

  useEffect(() => {
    setDataVersion((prevVersion) => prevVersion + 1);
    // Set endDate to current datetime
    const currentEndDate = new Date();

    // Calculate startDate based on the selected period
    let newStartDate;
    switch (period) {
      case "today":
        newStartDate = new Date(currentEndDate);
        newStartDate.setHours(0, 0, 0, 0); // Start of today
        break;
      case "week":
        newStartDate = new Date(currentEndDate);
        newStartDate.setDate(currentEndDate.getDate() - 7);
        newStartDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, milliseconds to 0
        break;
      case "month":
        newStartDate = new Date(currentEndDate);
        newStartDate.setMonth(currentEndDate.getMonth() - 1);
        newStartDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, milliseconds to 0
        break;
      default:
        newStartDate = new Date(currentEndDate);
        break;
    }

    // Update state with calculated dates
    setEndDate(currentEndDate.getTime());
    setStartDate(newStartDate.getTime());
  }, [period]);

  const productList = dashboardData?.most_mentioned_products ?? [];

  const uniqueTeams = useMemo(() => {
    const teams = agents.map((agent) => agent.team);
    return [...new Set(teams)];
  }, [agents]);

  const handleDateRangeChange = (event, newValue) => {
    console.log("Date range", newValue);
    setDialogDateRange(newValue);
    setDialogStartDate(newValue[0]);
    setDialogEndDate(newValue[1]);
  };

  const handlePeriodChange = (item) => {
    setPeriod(item);
    refetchDashboard();
  };

  const handleApplyFilters = () => {
    setStartDate(dialogStartDate);
    setEndDate(dialogEndDate);
    setDateRange(dialogDateRange);
    setAgentId(dialogAgentId);
    setTeam(dialogTeam);
    setStatus(dialogStatus);
    setLanguage(dialogLanguage);
    setOpenFilterDialog(false);
    refetchCalls(); // Trigger refetch after applying filters
  };

  const handleDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleDialogOpen = (event) => {
    setAnchorEl(event.currentTarget);
    // Initialize dialog state with current filters
    setDialogStartDate(startDate);
    setDialogEndDate(endDate);
    setDialogDateRange([startDate, endDate]);
    setDialogAgentId(agentId);
    setDialogTeam(team);
    setDialogStatus(status);
    setDialogLanguage(language);
    setOpenFilterDialog(true);
  };

  const handleFeedbackClick = () => {
    setFeedbackDialogOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackDialogOpen(false);
  };

  const handleFeedbackGotIt = () => {
    dispatch(setShowFeedbackReminder(false));
  };

  const handleClose = () => {
    setBackdropOpen(false);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)", // 12 columns
        gridTemplateRows: "repeat(13, 1fr)", // 8 rows
        gap: theme.spacing(0.8), // spacing between grid items
        padding: 0,
        height: "100%",
      }}
    >
      <WidgetBox
        width={"100%"}
        background="transparent"
        sx={{
          padding: 0,
          borderRadius: 0,
          gridColumn: "span 12",
          gridRow: "span 1",
          "&:hover": {
            boxShadow: "none",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          paddingTop={theme.spacing(0.8)}
          paddingBottom={theme.spacing(1)}
        >
          <Stack direction={"column"}>
            <Typography variant="h1" color="textPrimary">
              Dashboard
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Aggregated conversation statistics for {period}
            </Typography>
          </Stack>
          <Box
            display="flex"
            flexDirection="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={2}
            height={"100%"}
          >
            <AnimatedButton
              onClick={handleFeedbackClick}
              ref={feedbackButtonRef}
            >
              <Typography variant="body" color={theme.palette.neutral.black}>
                Leave Feedback
              </Typography>
            </AnimatedButton>
            <FeedbackDialog
              open={feedbackDialogOpen}
              onClose={handleFeedbackClose}
            />

            {/* <SimpleBackdropWithBox
              open={true}
              onClose={handleClose}
              text={"som text"}
            ></SimpleBackdropWithBox> */}

            <HorizontalSwitch
              items={periods}
              selectedItem={period}
              onItemClick={handlePeriodChange}
            />
          </Box>
        </Box>
      </WidgetBox>

      <WidgetBox
        background={theme.palette.neutral.black}
        // background={`radial-gradient(circle 200px at 85% 160%, ${theme.palette.primary.main}, ${theme.palette.neutral.black})`}
        sx={{
          //
          alignItems: "center",
          gridColumn: "span 2",
          gridRow: "span 4",
          border: `2px solid ${
            theme.palette.mode === "dark"
              ? theme.palette.background.widget
              : theme.palette.background.default
          }`,
        }}
      >
        <Box
          color={theme.palette.neutral.white}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2" color={theme.palette.neutral.white}>
              AI score
            </Typography>
          </Box>
        </Box>
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative" // Added relative positioning
        >
          <Gauge value={dashboardData.average_ai_score} />
          <Box
            position="absolute" // Position this box absolutely within the relative container
            top="50%"
            left="35%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4" color={theme.palette.neutral.white}>
              <strong>{dashboardData.average_ai_score}</strong>
            </Typography>
          </Box>

          <Box
            position="absolute" // Position this box absolutely within the relative container
            top="-5%"
            left="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            transform="translate(-50%, -50%)"
            bgcolor="rgba(256, 256, 256, 0.1)" // Semi-transparent background
            border={`1px solid ${theme.palette.neutral.black}`}
            boxShadow={`0 4px 8px ${theme.palette.neutral.black}`}
            color={theme.palette.neutral.white}
            padding="10px"
            borderRadius="8px"
            sx={{ backdropFilter: "blur(4px)" }}
          >
            <Typography variant="body3" color={theme.palette.neutral.white}>
              Based on {dashboardData.total_calls_processed} conversations
            </Typography>
          </Box>
        </Box>
      </WidgetBox>

      <WidgetBox
        loading={isLoadingDashboard}
        sx={{
          //
          gridColumn: "span 2",
          gridRow: "span 4",
        }}
      >
        <TextWidget
          title="Conversations"
          mainText={dashboardData.total_calls_processed}
          description="Total number of conversations"
        ></TextWidget>
      </WidgetBox>

      <WidgetBox
        gradient={true}
        loading={isLoadingDashboard}
        sx={{
          //
          gridColumn: "span 6",
          gridRow: "span 4",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h2">Score dynamic</Typography>
        </Box>
        {/* <Stack direction="row" gap={1} overflow="hidden"> */}
        <Box height="100%" width="100%" padding={1}>
          {isLoadingDashboard ? (
            <CircularProgress />
          ) : dashboardData ? (
            <LineChart key={dataVersion} dashboardData={dashboardData} />
          ) : (
            <p>No data available</p>
          )}
        </Box>
      </WidgetBox>

      <WidgetBox
        loading={isLoadingDashboard}
        sx={{
          gridColumn: "span 2",
          gridRow: "span 6",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="h2">Top agents</Typography>
          </Box>
        </Box>
        <Box height="100%" width="100%" overflow="hidden">
          <LeaderBoard agent_leaderboard={dashboardData.top_3_agents} />;
        </Box>
      </WidgetBox>

      {/* Table with Calls */}

      <WidgetBox
        sx={{
          gridColumn: "span 8",
          gridRow: "span 8",
          overflow: "hidden",
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box>
            <Typography variant="h2">Processed Calls</Typography>
          </Box>

          <Stack
            direction={"row"}
            // gap={1}
            // px={theme.spacing(0.8)}
          >
            <CustomFab
              active={undefined}
              size="small"
              onClick={handleDialogOpen}
            >
              <Funnel size={16} />
            </CustomFab>

            <CustomFab active={undefined} size="small" sx={{ mx: 2 }}>
              <DownloadSimple size={16} />
            </CustomFab>

            {/* <CustomIconButton aria-label="delete" size="medium">
              <Funnel color={theme.palette.neutral.black} size={16} />
            </CustomIconButton>

            <CustomIconButton aria-label="delete" size="medium">
              <DownloadSimple color={theme.palette.neutral.black} size={16} />
            </CustomIconButton>

            <CustomIconButton aria-label="delete" size="medium">
              <ArrowUpRight color={theme.palette.neutral.black} size={16} />
            </CustomIconButton> */}

            {/* <CustomFab disableRipple size="small" onClick={handleExpand}>
                <ArrowUpRight color={theme.palette.neutral.white} size={16} />
              </CustomFab> */}
          </Stack>
        </Box>
        <CallList calls={fetchedCalls}></CallList>
      </WidgetBox>

      <WidgetBox
        sx={{
          //
          gridColumn: "span 2",
          gridRow: "span 4",
        }}
      >
        <TextWidget
          title="Active agents"
          mainText={dashboardData.active_agents}
          description="Team members with processed calls"
          descriptionWidth="75%"
        ></TextWidget>
      </WidgetBox>

      <WidgetBox
        background={theme.palette.primary.main}
        // background={`linear-gradient(160deg, ${theme.palette.primary[500]} 50%, ${theme.palette.primary[500]} 60%, ${theme.palette.secondary.main} 100%)`}
        sx={{
          //
          gridColumn: "span 2",
          gridRow: "span 6",
        }}
      >
        <TextWidget
          title="Processed minutes"
          textColor={theme.palette.neutral.black}
          mainText={
            <>
              <strong>{dashboardData.total_minutes_processed}</strong> minutes
            </>
          }
          description="Total duration of processed conversations"
        ></TextWidget>
      </WidgetBox>

      <WidgetBox
        sx={{
          //
          gridColumn: "span 2",
          gridRow: "span 4",
        }}
      >
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems={"start"}
        >
          <Typography variant="h2">Product mentions</Typography>
          {productList.length > 0 ? (
            <ChipList items={productList} random_colors={true} />
          ) : (
            <Typography variant="body2" color="textSecondary">
              No products were mentioned.
            </Typography>
          )}
        </Box>
      </WidgetBox>
      <Backdrop
        open={openFilterDialog}
        sx={{ zIndex: (theme) => theme.zIndex.drawer }}
        onClick={handleDialogClose}
      />
      <Popper
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openFilterDialog}
        anchorEl={anchorEl}
        placement="left"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10], // Adjust as needed for horizontal offset
            },
          },
          {
            name: "flip",
            options: {
              altBoundary: true,
              fallbackPlacements: ["left", "right"],
            },
          },
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              boundary: "viewport",
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleDialogClose}>
            <Paper sx={{ p: 2, mt: 1 }}>
              <Typography gutterBottom>Filters</Typography>
              <Box sx={{ width: 300, mt: 4 }}>
                <Typography gutterBottom>Filter by Date</Typography>
                <Slider
                  value={dialogDateRange}
                  onChange={handleDateRangeChange}
                  valueLabelDisplay="auto"
                  min={new Date("2024-01-01").getTime()}
                  max={new Date("2024-12-31").getTime()}
                  valueLabelFormat={(value) =>
                    formatDate(new Date(value), "yyyy-MM-dd")
                  }
                />
              </Box>
              <Autocomplete
                options={agents}
                getOptionLabel={(option) => option.name}
                value={
                  agents.find((agent) => agent._id === dialogAgentId) || null
                }
                onChange={(e, value) => setDialogAgentId(value?._id || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Agent"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
              />
              <Autocomplete
                options={uniqueTeams}
                getOptionLabel={(option) => option}
                value={dialogTeam}
                onChange={(e, value) => setDialogTeam(value || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
              />
              <Autocomplete
                options={["Status1", "Status2", "Status3"]} // Example status options
                getOptionLabel={(option) => option}
                value={dialogStatus}
                onChange={(e, value) => setDialogStatus(value || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
              />
              <Autocomplete
                options={["English", "Spanish", "French"]} // Example language options
                getOptionLabel={(option) => option}
                value={dialogLanguage}
                onChange={(e, value) => setDialogLanguage(value || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Language"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
              />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button
                  onClick={handleDialogClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleApplyFilters}
                  color="primary"
                  variant="contained"
                >
                  Apply
                </Button>
              </Box>
            </Paper>
          </ClickAwayListener>
        )}
      </Popper>
      {/* Advice backdrop */}
      {showTutorial && (
        <TutorialBackdrop
          anchorEl={feedbackButtonRef.current}
          open={backdropOpen}
          onClose={handleClose}
          advicePosition="bottom"
        >
          <Typography variant="body2" sx={{ mt: 1 }}>
            We appreciate your feedback and feature requests. We would love to
            hear your thoughts during our Open Beta.
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 1 }}
            onClick={handleFeedbackGotIt}
          >
            Got it. Will do!
          </Button>
        </TutorialBackdrop>
      )}
    </Box>
  );
};

export default Dashboard;

const { Box, CircularProgress } = require("@mui/material");
const { styled } = require("@mui/system");

const hexToRgba = (hex, opacity) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const WidgetBox = styled(
  ({ loading = false, children, background, gradient, sx, ...props }) => (
    <Box sx={sx} {...props}>
      {loading ? <CircularProgress /> : children}
    </Box>
  )
)(({ theme, background, gradient }) => {
  // Determine the box shadow color based on the theme mode
  const shadowColor =
    theme.palette.mode === "dark"
      ? hexToRgba(theme.palette.primary.main, 0.02)
      : hexToRgba(theme.palette.neutral.black, 0.04);

  // Common styles
  const commonStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    background: background || theme.palette.background.widget,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    height: "100%",
    paddingTop: theme.spacing(1.3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 0,
    position: "relative",
    overflow: "hidden",
  };

  // Gradient background
  const gradientBackground = gradient
    ? {
        "&::before": {
          content: '""',
          position: "absolute",
          top: "80%",
          left: "35%",
          width: "90%",
          height: "15%",
          transform: "translate(-50%, -50%)",
          background: `radial-gradient(ellipse at center, ${theme.palette.primary.main}, ${theme.palette.background.widget})`,
          filter: "blur(50px)",
          opacity: 0.2,
          // zIndex: 0,
        },
      }
    : {};

  return {
    ...commonStyles,
    ...gradientBackground,
    "& > *": {
      position: "relative",
      // zIndex: 1,
    },
  };
});

export default WidgetBox;

import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { styled } from "@mui/system";

const AnimatedBox = styled(motion.div)(({ theme }) => ({
  display: "inline-block",
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  backgroundSize: "200% 200%",
  color: theme.palette.primary.contrastText,
  cursor: "pointer",
  textAlign: "center",
}));

const gradientAnimation = {
  animate: {
    backgroundPosition: [
      "50% 50%", // Center
      "75% 25%", // Top right
      "100% 50%", // Right center
      "75% 75%", // Bottom right
      "50% 100%", // Bottom center
      "25% 75%", // Bottom left
      "0% 50%", // Left center
      "25% 25%", // Top left
      "50% 0%", // Top center
      "75% 25%", // Top right again to complete the loop
      "50% 50%", // Center
    ],
    transition: {
      duration: 6,
      ease: "easeInOut",
      repeat: Infinity,
    },
  },
};

const AnimatedButton = forwardRef(({ children, onClick }, ref) => (
  <Box
    component={AnimatedBox}
    onClick={onClick}
    ref={ref}
    initial="initial"
    animate="animate"
    variants={gradientAnimation}
  >
    {children}
  </Box>
));

export default AnimatedButton;

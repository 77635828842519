import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material/styles";
import { Box, Chip, CircularProgress } from "@mui/material";

// Function to scale data from 0 to 10
const scaleData = (data, scaleMin, scaleMax) => {
  if (!data || data.length === 0) return [];
  const yValues = data.map((point) => point.y);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  return data.map((point) => ({
    x: point.x,
    y: ((point.y - minY) / (maxY - minY)) * (scaleMax - scaleMin) + scaleMin,
    originalY: point.y, // Keep the original y value for tooltips
  }));
};

// Function to adapt and scale dashboard data
const adaptData = (data = []) => {
  const aiScoreData = data.ai_score_dynamics.map((point) => ({
    x: point.x,
    y: point.y,
    originalY: point.y, // Keep the original y value for tooltips
  }));

  const callCountData = scaleData(data.call_processed_dynamics, 0, 10);

  return {
    plotData: [
      {
        id: "AI Score",
        data: aiScoreData,
      },
      {
        id: "Call Count",
        data: callCountData,
      },
    ],
    dataLength: aiScoreData.length,
  };
};

const LineChart = ({ dashboardData = {} }) => {
  const theme = useTheme();
  if (
    !dashboardData ||
    !dashboardData.ai_score_dynamics ||
    !dashboardData.call_processed_dynamics
  ) {
    return <></>;
  }

  const { plotData, dataLength } = dashboardData
    ? adaptData(dashboardData)
    : { plotData: [], dataLength: 0 };
  // const tickValues = dataLength > 14 ? "every 3" : undefined;
  let tickValues = [];
  const allXValues = plotData[0].data.map((point) => point.x);
  if (dataLength > 14) {
    tickValues = allXValues.filter((_, index) => index % 3 === 0);
  } else {
    tickValues = allXValues;
  }

  console.log("Plot data", plotData);
  return (
    <ResponsiveLine
      data={plotData}
      margin={{ top: 10, right: 30, bottom: 30, left: 30 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: -10,
        max: 20,
        stacked: false,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickValues: tickValues,
        orient: "bottom",
        tickSize: 0, // Remove tick marks
        tickPadding: 5,
        tickRotation: 0,
        // legend: "Time Period",
        // legendOffset: 36,
        // legendPosition: "middle",
        // renderTick,
      }}
      axisLeft={null}
      pointSize={0} // Remove points
      enableGridX={true} // Disable grid lines on X-axis
      enableGridY={false} // Disable grid lines on Y-axis
      curve="natural" // Make turns smooth and rounded
      lineWidth={2}
      colors={[theme.palette.primary.main, theme.palette.neutral.gray]} // Set colors from theme
      enableTouchCrosshair={true}
      enableSlices="x" // Enable slices to show tooltip for both lines
      sliceTooltip={({ slice }) => {
        const aiScoreData = slice.points.find(
          (point) => point.serieId === "AI Score"
        );
        const callCountData = slice.points.find(
          (point) => point.serieId === "Call Count"
        );

        return (
          <div
            style={{
              background: "transparent",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            {aiScoreData && (
              <Chip
                size="small"
                label={`AI Score: ${aiScoreData.data.originalY}`}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.neutral.black,
                }}
              />
            )}
            {callCountData && (
              <Chip
                size="small"
                label={`Calls Processed: ${callCountData.data.originalY}`}
                style={{
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.text.primary,
                }}
              />
            )}
          </div>
        );
      }}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: "none", // Remove x-axis line
            },
          },
          ticks: {
            text: {
              fill: theme.palette.text.primary,
            },
          },
        },
        grid: {
          line: {
            stroke: theme.palette.neutral.gray,
            strokeWidth: 1,
            strokeDasharray: "6 6",
          },
        },
        crosshair: {
          line: {
            stroke: theme.palette.neutral.alternative,
            strokeWidth: 1,
            strokeDasharray: "6 6",
          },
        },
      }}
    />
  );
};

export default LineChart;

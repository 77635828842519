import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Stack,
  IconButton,
  Popover,
} from "@mui/material";
import { Info } from "@phosphor-icons/react";

import { styled, useTheme } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `${theme.palette.background.default}`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: `linear-gradient(140deg, ${theme.palette.secondary.main} 10%, ${theme.palette.primary.main} 60%, ${theme.palette.primary.main} 100%)`,
  },
}));

const transformStagesData = (data) => {
  return Object.keys(data).map((key) => ({
    name: key,
    ...data[key],
  }));
};

const ConversationStages = ({ stages }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
  };

  const open = Boolean(anchorEl);

  const transformedStages = transformStagesData(stages);

  return (
    <Box height={"100%"}>
      <List sx={{ height: "100%", overflow: "auto" }}>
        {transformedStages.map((stage, index) => (
          <ListItem
            key={index}
            sx={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <ListItemText primary={stage.name} />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={1}
              >
                <IconButton
                  onClick={(event) =>
                    handlePopoverOpen(event, stage.score_explanation)
                  }
                >
                  <Info size={16} color={theme.palette.text.primary} />
                </IconButton>

                <Typography variant="h2">{stage.score}</Typography>
              </Box>
            </Box>
            <Box width="100%" mt={1}>
              {stage.answer === "yes" ? (
                <BorderLinearProgress
                  variant="determinate"
                  value={stage.score * 10}
                />
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Not present in conversation
                </Typography>
              )}
            </Box>
          </ListItem>
        ))}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <Box p={2} width={200}>
            <Typography variant="body2">{popoverContent}</Typography>
          </Box>
        </Popover>
      </List>
    </Box>
  );
};

export default ConversationStages;

import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../components/SideBar";
import { useTheme } from "@emotion/react";

const Layout = () => {
  const theme = useTheme();
  return (
    <Box display="flex" width="100%" height="100%">
      <SideBar />
      <Box
        component="main"
        flexGrow={1}
        marginTop={theme.spacing(2.5)}
        marginBottom={theme.spacing(2.5)}
        marginRight={theme.spacing(2.5)}
        marginLeft={theme.spacing(12.5)}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default Layout;

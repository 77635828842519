import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import WidgetBox from "../../components/WidgetBox";
import Teams from "../../components/Teams";

const Agents = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)", // 12 columns
        gridTemplateRows: "repeat(13, 1fr)", // 8 rows
        gap: theme.spacing(0.8), // spacing between grid items
        padding: 0,
        height: "100%",
      }}
    >
      <WidgetBox
        background="transparent"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          padding: 0,
          borderRadius: 0,
          gridColumn: "span 12",
          gridRow: "span 1",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Stack paddingBottom={theme.spacing(1)} direction={"column"}>
          <Typography variant="h1" color="textPrimary">
            Teams
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Add or edit information about team members
          </Typography>
        </Stack>
      </WidgetBox>

      <WidgetBox
        sx={{
          gridColumn: "span 12",
          gridRow: "span 12",
        }}
      >
        <Teams></Teams>
      </WidgetBox>
    </Box>
  );
};

export default Agents;

import React, { useEffect, useState } from "react";
import { Box, Backdrop, ClickAwayListener } from "@mui/material";

// import zIndex from "@mui/material/styles/zIndex";

const getPosition = (anchorEl, advicePosition, padding = 18) => {
  if (!anchorEl)
    return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const rect = anchorEl.getBoundingClientRect();
  const scrollY = window.scrollY;
  const scrollX = window.scrollX;

  switch (advicePosition) {
    case "top":
      return {
        top: rect.top + scrollY - padding,
        left: rect.left + scrollX + rect.width / 2,
        transform: "translate(-50%, -100%)",
      };
    case "bottom":
      return {
        top: rect.bottom + scrollY + padding,
        left: rect.left + scrollX + rect.width / 2,
        transform: "translate(-50%, 0)",
      };
    case "left":
      return {
        top: rect.top + scrollY + rect.height / 2,
        left: rect.left + scrollX - padding,
        transform: "translate(-100%, -50%)",
      };
    case "right":
      return {
        top: rect.top + scrollY + rect.height / 2,
        left: rect.right + scrollX + padding,
        transform: "translate(0, -50%)",
      };
    default:
      return {};
  }
};

const TutorialBackdrop = ({
  anchorEl,
  children,
  open,
  onClose,
  advicePosition = "top",
}) => {
  const [contentOpen, setContentOpen] = useState(open);

  useEffect(() => {
    setContentOpen(open);
  }, [open]);

  const handleClose = () => {
    setContentOpen(false);
    onClose();
  };

  const position = getPosition(anchorEl, advicePosition);

  return (
    <>
      <Backdrop
        open={contentOpen}
        sx={{ zIndex: (theme) => theme.zIndex.drawer }}
      />
      {contentOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              position: "absolute",
              ...position,
              zIndex: (theme) => theme.zIndex.modal,
              backgroundColor: "white",
              boxShadow: 3,
              borderRadius: 1,
              p: 2,
              "&::before": {
                content: '""',
                position: "absolute",
                borderWidth: "10px",
                borderStyle: "solid",
                ...(advicePosition === "top" && {
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderColor: "white transparent transparent transparent",
                }),
                ...(advicePosition === "bottom" && {
                  bottom: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderColor: "transparent transparent white transparent",
                }),
                ...(advicePosition === "left" && {
                  left: "100%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  borderColor: "transparent transparent transparent white",
                }),
                ...(advicePosition === "right" && {
                  right: "100%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  borderColor: "transparent white transparent transparent",
                }),
              },
            }}
          >
            {children}
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};

export default TutorialBackdrop;

import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

const SwitchBox = styled(Box)(({ theme, selected }) => {
  const isDarkMode = theme.palette.mode === "dark";

  return {
    padding: theme.spacing(0.8, 2),
    margin: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: selected
      ? isDarkMode
        ? theme.palette.neutral.white
        : theme.palette.neutral.black
      : theme.palette.background.default,
    color: selected
      ? isDarkMode
        ? theme.palette.neutral.black
        : theme.palette.neutral.white
      : theme.palette.text.primary,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: selected ? null : theme.palette.action.hover,
    },
  };
});

const HorizontalSwitch = ({ items, selectedItem, onItemClick }) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        borderRadius: (theme) => theme.shape.borderRadius,
        border: (theme) => `2px solid ${theme.palette.background.widget}`,
        boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.2)",
        padding: (theme) => theme.spacing(0.2),
      }}
    >
      {items.map((item) => (
        <SwitchBox
          key={item}
          selected={selectedItem === item}
          onClick={() => onItemClick(item)}
        >
          <Typography variant="body" color="inherit">
            {item}
          </Typography>
        </SwitchBox>
      ))}
    </Stack>
  );
};

export default HorizontalSwitch;

import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const TextWidget = ({
  title,
  mainText,
  description,
  descriptionWidth = "100%",
  textColor = "textPrimary",
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Typography variant="h2" color={textColor}>
        {title}
      </Typography>
      <Box>
        <Typography variant="h3" color={textColor}>
          {mainText}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ width: descriptionWidth }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default TextWidget;

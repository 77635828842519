import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Container, Box, TextField, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLoginMutation } from "../../state/api";
import { setCredentials } from "../../state/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const theme = useTheme();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const userData = await login(data).unwrap();
      dispatch(
        setCredentials({ token: userData.access_token, user: data.username })
      );
      navigate("/dashboard");
    } catch (err) {
      console.error("Failed to log in: ", err);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        backgroundColor={theme.palette.background.default}
        padding={theme.spacing(4)}
        borderRadius={theme.shape.borderRadius / 16}
        sx={{
          marginTop: theme.spacing(12),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(150deg, rgba(224, 253, 7, 0.9), rgba(129, 183, 247, 0.6))",
            zIndex: -1,
            filter: "blur(25px)",
          },
        }}
      >
        {/* <Typography variant="h2" color={theme.palette.text.primary}>
          Login
        </Typography> */}
        <Box
          component="img"
          sx={{
            width: "80%",
            height: "auto",
            padding: theme.spacing(1),
            my: theme.spacing(2),
          }}
          alt="Logo Login"
          src={
            theme.palette.mode === "dark"
              ? `${process.env.PUBLIC_URL}/logo_text_labs_white.png`
              : `${process.env.PUBLIC_URL}/logo_text_labs_black.png`
          }
        />
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: "100%",
            mt: 1,
            color: `${theme.palette.text.primary}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Controller
            name="username"
            control={control}
            defaultValue=""
            rules={{ required: "Username is required" }}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="username"
                label="Username"
                autoComplete="username"
                autoFocus
                error={!!errors.username}
                helperText={errors.username ? errors.username.message : ""}
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: "Password is required" }}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                {...field}
              />
            )}
          />
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            sx={{
              width: "50%",
              mt: 4,
              mb: 2,
              p: 1,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <Typography
              variant="body"
              color={theme.palette.primary.contrastText}
            >
              Login
            </Typography>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

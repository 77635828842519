import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Slider,
  useTheme,
  Typography,
  Divider,
  Tooltip,
  Stack,
} from "@mui/material";
import {
  Play,
  Pause,
  Cube,
  CrosshairSimple,
  Warning,
  SpeakerSimpleLow,
  SpeakerSimpleHigh,
} from "@phosphor-icons/react";
import WaveSurfer from "wavesurfer.js";
import { styled } from "@mui/system";
import { createRoot } from "react-dom/client";

const GradientBoxContainer = styled(Box)(({ theme }) => {
  const isDarkMode = theme.palette.mode === "dark";

  return {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "10%",
      background: `linear-gradient(to bottom, ${theme.palette.background.widget}, ${theme.palette.neutral.black}00)`,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "10%",
      background: `linear-gradient(to top, ${theme.palette.background.widget}, ${theme.palette.neutral.black}00)`,
    },
  };
});

const AudioPlayer = ({
  audioFile,
  transcript,
  products,
  targets,
  flags,
  speechMetrics,
}) => {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const transcriptionRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const theme = useTheme();

  const getMarkers = (data, color, icon) => {
    if (!data) return []; // Handle null or undefined data
    return Object.entries(data).flatMap(([label, { times }], index) =>
      times.map((time, timeIndex) => ({
        id: `${label}-${index}-${timeIndex}`,
        time,
        label,
        color,
        icon,
      }))
    );
  };

  const productMarkers = products
    ? getMarkers(
        products,
        `${theme.palette.primary.main}`,
        <Cube
          classname="icon-box"
          color={theme.palette.primary.main}
          size={16}
        />
      )
    : [];
  const targetMarkers = targets
    ? getMarkers(
        targets,
        `${theme.palette.secondary.main}`,
        <CrosshairSimple
          classname="icon-box"
          color={theme.palette.secondary.main}
          size={16}
        />
      )
    : [];
  const flagMarkers = flags
    ? getMarkers(
        flags,
        `${theme.palette.error.main}`,
        <Warning
          classname="icon-box"
          color={theme.palette.error.main}
          size={16}
        />
      )
    : [];

  const markers = [...productMarkers, ...targetMarkers, ...flagMarkers];

  useEffect(() => {
    const scrollToCurrentTime = (time) => {
      if (transcriptionRef.current) {
        const wordElements =
          transcriptionRef.current.querySelectorAll("[data-start-time]");
        for (let wordElement of wordElements) {
          const startTime = parseFloat(
            wordElement.getAttribute("data-start-time")
          );
          const endTime = parseFloat(wordElement.getAttribute("data-end-time"));
          if (time >= startTime && time <= endTime) {
            wordElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            break;
          }
        }
      }
    };

    scrollToCurrentTime(currentTime);
  }, [currentTime]);

  useEffect(() => {
    if (waveformRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: theme.palette.text.primary,
        progressColor: [
          theme.palette.primary.main,
          theme.palette.secondary.main,
        ],
        // height: 100,
        barWidth: 1.5,
        barHeight: 1,
        barGap: 2,
        normalize: false,
        responsive: true,
        hideScrollbar: true,
        fillParent: true,
        url: audioFile,
      });

      // wavesurferRef.current.load(audioFile);
    }

    wavesurferRef.current.on("ready", () => {
      setDuration(wavesurferRef.current.getDuration());
    });

    wavesurferRef.current.on("audioprocess", () => {
      setCurrentTime(wavesurferRef.current.getCurrentTime());
    });

    wavesurferRef.current.on("interaction", () => {
      const currentTime = wavesurferRef.current.getCurrentTime();
      setCurrentTime(currentTime);
    });

    return () => {
      if (wavesurferRef.current) {
        try {
          wavesurferRef.current.destroy();
        } catch (error) {
          console.error("Error while destroying WaveSurfer instance:", error);
        }
      }
    };
  }, [
    audioFile,
    theme.palette.secondary.main,
    theme.palette.primary.main,
    theme.palette.text.primary,
  ]);

  const drawMarkers = () => {
    const markersContainer = document.getElementById("markers-container");
    if (markersContainer) {
      markersContainer.innerHTML = ""; // Clear any existing markers
      markers.forEach((marker) => {
        const markerPosition = (marker.time / duration) * 100;
        const markerColor = marker.color;
        const markerIcon = marker.icon;

        const markerElement = document.createElement("div");
        const root = createRoot(markerElement);
        root.render(
          <Box
            sx={{
              position: "absolute",
              left: `${markerPosition}%`,
              top: 0,
              height: "20%",
              display: "flex",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                backgroundColor: markerColor,
                width: 1.5,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "0px",
                left: "5px",
                "&:hover .stack-box": {
                  backgroundColor: marker.color,
                  "& .label-box": {
                    visibility: "visible",
                    color: `${theme.palette.neutral.black}`, // Change text color on hover
                  },
                  "& .icon-box": {
                    color: "#000", // Change icon color on hover
                  },
                },
              }}
            >
              <Stack
                direction="row"
                width="100%"
                sx={{
                  px: 0.2,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 2,
                }}
                className="stack-box"
                // sx={{ backgroundColor: markerColor }}
              >
                {marker.icon}

                <Typography
                  variant="body"
                  className="label-box"
                  sx={{
                    paddingLeft: 1,
                    fontSize: 10,
                    visibility: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {marker.label}
                </Typography>
              </Stack>
            </Box>
          </Box>
        );

        // Append markerElement to the markers-container Box
        markersContainer.appendChild(markerElement);
      });
    }
  };

  useEffect(() => {
    if (duration > 0) {
      drawMarkers();
    }
  }, [duration]);

  const handlePlayPause = () => {
    if (wavesurferRef.current.isPlaying()) {
      wavesurferRef.current.pause();
      setIsPlaying(false);
    } else {
      wavesurferRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleMute = () => {
    wavesurferRef.current.setMute(!isMuted);
    setIsMuted(!isMuted);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    wavesurferRef.current.setVolume(newValue);
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        marginTop={1}
      >
        <Box sx={{ position: "relative", width: "90%", marginTop: 2 }}>
          <Box
            id="markers-container"
            sx={{ position: "absolute", top: 0, width: "100%", height: "100%" }}
          />
          <Box ref={waveformRef} sx={{ width: "100%", height: "100%" }} />
        </Box>
        <Box
          marginTop={1}
          display="flex"
          flexDirection="flex-row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row">
            <Box
              sx={{
                borderRadius: theme.shape.borderRadius,
                border: `2px solid ${theme.palette.background.default}`,
                height: "100%",
                display: "flex",
                alignItems: "center",
                margin: theme.spacing(1),
                px: theme.spacing(2),
                py: theme.spacing(0.5),
              }}
            >
              <Stack direction={"column"}>
                <Typography variant="body3" color="textSecondary">
                  Agent Pace
                </Typography>
                <Typography variant="body">
                  {speechMetrics.agent_pace} words / min
                </Typography>
              </Stack>
            </Box>

            <Box
              sx={{
                borderRadius: theme.shape.borderRadius,
                border: `2px solid ${theme.palette.background.default}`,
                height: "100%",
                display: "flex",
                alignItems: "center",
                margin: theme.spacing(1),
                px: theme.spacing(2),
                py: theme.spacing(0.5),
              }}
            >
              <Stack direction={"column"}>
                <Typography variant="body3" color="textSecondary">
                  Agent Speaking Time
                </Typography>
                <Typography variant="body">
                  {Math.round((speechMetrics.agent_speaking_time / 60) * 100) /
                    100}{" "}
                  minutes
                </Typography>
              </Stack>
            </Box>
          </Stack>

          <Stack direction="row">
            <IconButton
              disableRipple
              // disableFocusRipple
              onClick={handlePlayPause}
            >
              {isPlaying ? (
                <Pause color={theme.palette.text.primary} size={16} />
              ) : (
                <Play color={theme.palette.text.primary} size={16} />
              )}
            </IconButton>

            <Stack spacing={1} direction="row" alignItems="center">
              <SpeakerSimpleLow size={16} />
              <Slider
                size="small"
                aria-label="Volume"
                value={volume}
                onChange={handleVolumeChange}
                min={0}
                max={1}
                step={0.01}
                sx={{ width: 100, ml: 2 }}
              />
              <SpeakerSimpleHigh size={16} />
            </Stack>
          </Stack>
        </Box>
      </Box>

      {/* Transcription Area starts here */}
      <GradientBoxContainer>
        <Box
          ref={transcriptionRef}
          p={2}
          sx={{
            overflowY: "auto",
            maxHeight: "350px",
            marginTop: theme.spacing(1),
          }}
        >
          {transcript.map((segment, index) => {
            const hasHighlightedWord = segment.words.some(
              (word) =>
                currentTime >= word.start_time && currentTime <= word.end_time
            );
            return (
              <Box
                key={index}
                display="flex"
                justifyContent={
                  String(segment.speaker) === "0" ? "flex-start" : "flex-end"
                }
                mb={2}
              >
                <Box
                  p={2}
                  bgcolor={
                    String(segment.speaker) === "0"
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.background.default}`
                  }
                  color={
                    String(segment.speaker) === "0"
                      ? `${theme.palette.neutral.black}`
                      : `${theme.palette.text.primary}`
                  }
                  borderRadius={1}
                  maxWidth="70%"
                  sx={{
                    boxShadow: hasHighlightedWord
                      ? `0px 0px 8px ${theme.palette.primary.contrastText}40`
                      : "none",
                  }}
                >
                  {segment.words.map((word, idx) => (
                    <Typography
                      variant="body"
                      key={idx}
                      component="span"
                      data-highlighted={
                        currentTime >= word.start_time &&
                        currentTime <= word.end_time
                          ? true
                          : false
                      }
                      data-start-time={word.start_time}
                      data-end-time={word.end_time}
                      sx={{
                        borderRadius: 1,
                        padding: 0.3,
                        backgroundColor:
                          currentTime >= word.start_time &&
                          currentTime <= word.end_time
                            ? `${theme.palette.neutral.white}`
                            : "inherit",
                        color:
                          currentTime >= word.start_time &&
                          currentTime <= word.end_time
                            ? `${theme.palette.neutral.black}`
                            : "inherit",
                      }}
                    >
                      {word.text}{" "}
                    </Typography>
                  ))}
                </Box>
              </Box>
            );
          })}
        </Box>
      </GradientBoxContainer>
      {/* Transcription endds here */}
    </Box>
  );
};

export default AudioPlayer;

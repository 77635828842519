import React from "react";
import {
  Drawer,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  useTheme,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  HouseSimple,
  Faders,
  UploadSimple,
  UsersThree,
  SignOut,
  Sun,
  Moon,
} from "@phosphor-icons/react";
import Zoom from "@mui/material/Zoom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../state/authSlice";
import { setMode } from "../state";
import { useSelector } from "react-redux";
import CustomFab from "./CustomFab";

const menu_items = [
  { icon: <HouseSimple size={18} />, path: "/dashboard", text: "Home" },
  { icon: <UploadSimple size={18} />, path: "/upload", text: "Upload" },
  { icon: <UsersThree size={18} />, path: "/agents", text: "Team" },
  { icon: <Faders size={18} />, path: "/settings", text: "Settings" },
];

const SideBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/auth/login");
  };

  const handleThemeToggle = () => {
    dispatch(setMode());
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{
        sx: {
          width: 100,
          height: "100%",
          border: "none",
          display: "flex",
          flexDirection: "rows",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: 2,
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <Box
        component="img"
        sx={{
          width: 40,
          height: "auto",
          paddingTop: theme.spacing(1),
        }}
        alt="Logo"
        src={
          theme.palette.mode === "dark"
            ? `${process.env.PUBLIC_URL}/logo_white_192.png`
            : `${process.env.PUBLIC_URL}/logo_black_192.png`
        }
      />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <List>
          {menu_items.map((item, index) => (
            <ListItem
              key={index}
              component={Link}
              to={item.path}
              sx={{ justifyContent: "center", padding: 0.5 }}
            >
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="right"
                  title={item.text}
                  sx={{ color: theme.palette.neutral.white }}
                >
                  <CustomFab
                    disableRipple
                    size="small"
                    active={location.pathname === item.path ? true : undefined}
                  >
                    {item.icon}
                  </CustomFab>
                </Tooltip>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        gap={1}
        sx={{
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip
          TransitionComponent={Zoom}
          placement="right"
          title="Toggle Theme"
          sx={{ color: theme.palette.neutral.white }}
        >
          <CustomFab disableRipple size="small" onClick={handleThemeToggle}>
            {theme.palette.mode === "dark" ? (
              <Sun size={18} />
            ) : (
              <Moon size={18} />
            )}
          </CustomFab>
        </Tooltip>
        <Tooltip
          TransitionComponent={Zoom}
          placement="right"
          title="Sign Out"
          sx={{ color: theme.palette.neutral.white }}
        >
          <CustomFab disableRipple size="small" onClick={handleLogout}>
            <SignOut size={18} />
          </CustomFab>
        </Tooltip>
      </Box>
    </Drawer>
  );
};

export default SideBar;

import React from "react";
import { FormControlLabel, Checkbox, Typography } from "@mui/material";

const EnableMatchingCheckbox = ({
  isMatchingEnabled,
  setIsMatchingEnabled,
  theme,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isMatchingEnabled}
          onChange={(e) => setIsMatchingEnabled(e.target.checked)}
          sx={{
            color: theme.palette.neutral.white,
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
          }}
        />
      }
      label={
        <Typography sx={{ color: theme.palette.neutral.white }}>
          Enable matching by file name
        </Typography>
      }
    />
  );
};

export default EnableMatchingCheckbox;

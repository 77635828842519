import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut } from "./authSlice";

console.log("BASE URL", process.env.REACT_APP_BASE_URL);

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(logOut());
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "api",
  tagTypes: [
    "Call",
    "Auth",
    "Agent",
    "File",
    "AISettings",
    "Dashboard",
    "Feedback",
    "Settings",
  ],
  endpoints: (build) => ({
    login: build.mutation({
      query: (credentials) => ({
        url: "/auth/token",
        method: "POST",
        body: new URLSearchParams(credentials),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
      invalidatesTags: ["Auth"],
    }),

    getCall: build.query({
      query: (id) => `call?call_id=${id}`,
      providesTags: ["Call"],
    }),

    getCalls: build.query({
      query: ({ startDate, endDate, agentId, team, status, language }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start_date", startDate);
        if (endDate) params.append("end_date", endDate);
        if (agentId) params.append("agent_id", agentId);
        if (team) params.append("team", team);
        if (status) params.append("status", status);
        if (language) params.append("language", language);

        return `calls?${params.toString()}`;
      },
      providesTags: ["Call"],
    }),

    getCallAnalyticsSummary: build.query({
      query: (id) => `call-analytics-summary?call_id=${id}`,
      providesTags: ["Call"],
    }),

    getDashboardData: build.query({
      query: (period) => `dashboard?period=${period}`,
      providesTags: ["Dashboard"],
    }),

    createAgent: build.mutation({
      query: (newAgent) => ({
        url: "/agents/create",
        method: "POST",
        body: newAgent,
      }),
      invalidatesTags: ["Agent"],
    }),

    getAgents: build.query({
      query: () => ({
        url: "/agents",
        method: "GET",
      }),
      providesTags: ["Agent"],
    }),

    deleteAgent: build.mutation({
      query: (agentId) => ({
        url: `/agents/${agentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Agent"],
    }),

    uploadFiles: build.mutation({
      query: (formData) => ({
        url: "/upload",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["File"],
    }),

    matchFiles: build.mutation({
      query: (data) => ({
        url: "/map-agents",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["File"],
    }),

    getAISettings: build.query({
      query: () => ({
        url: "/ai-settings",
        method: "GET",
      }),
      providesTags: ["AISettings"],
    }),

    getLanguages: build.query({
      query: () => ({
        url: "/languages",
        method: "GET",
      }),
      providesTags: ["Settings"],
    }),

    updateAISettings: build.mutation({
      query: ({ field, value }) => ({
        url: `/ai-settings/${field}`,
        method: "PATCH",
        body: value,
      }),
      invalidatesTags: ["AISettings"],
    }),

    sendFeedback: build.mutation({
      query: (newFeedback) => ({
        url: "/feedback",
        method: "POST",
        body: newFeedback,
      }),
      invalidatesTags: ["Feedback"],
    }),

    getTreeViewCalls: build.query({
      query: ({ level, parentId, startDate, endDate, selectedDate }) => ({
        url: "/calls-tree-view",
        method: "GET",
        params: {
          level,
          parent_id: parentId,
          start_date: startDate,
          end_date: endDate,
          selected_date: selectedDate,
        },
      }),
      providesTags: ["Call"],
    }),
  }),
});

export const {
  useGetCallQuery,
  useLoginMutation,
  useGetCallsQuery,
  useCreateAgentMutation,
  useGetAgentsQuery,
  useDeleteAgentMutation,
  useUploadFilesMutation,
  useMatchFilesMutation,
  useGetAISettingsQuery,
  useUpdateAISettingsMutation,
  useGetCallAnalyticsSummaryQuery,
  useGetDashboardDataQuery,
  useSendFeedbackMutation,
  useGetLanguagesQuery,
  useGetTreeViewCallsQuery,
  useLazyGetTreeViewCallsQuery,
} = api;

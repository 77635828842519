import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { useGetAgentsQuery } from "../../state/api";
import InitialUploadStep from "./InitialUploadStep";
import MatchFilesStep from "./MatchFilesStep";
import UploadFilesStep from "./UploadFilesStep";
import ConfirmationStep from "./ConfirmationStep";
import EnableMatchingCheckbox from "./EnableMatchingCheckbox";
import ManualSelectionForm from "./ManualSelectionForm";

const FileUploader = ({ onUploadComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [matchedFiles, setMatchedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMatchingEnabled, setIsMatchingEnabled] = useState(false);
  const [language, setLanguage] = useState("");
  const [agent, setAgent] = useState("");
  const [agentId, setAgentId] = useState(null);
  const [team, setTeam] = useState("");
  const theme = useTheme();

  const { data: agents = [], isLoading } = useGetAgentsQuery();

  const onDrop = (acceptedFiles) => {
    setErrorMessage("");
    const filteredFiles = acceptedFiles.filter(
      (file) => file.type === "audio/wav" || file.type === "audio/mpeg"
    );

    if (filteredFiles.length !== acceptedFiles.length) {
      setErrorMessage("Only .wav and .mp3 files are allowed.");
    } else {
      setErrorMessage("");
    }

    setUploadedFiles([...uploadedFiles, ...filteredFiles]);
    setFileNames([...fileNames, ...filteredFiles.map((file) => file.name)]); // Collect filenames
  };

  useEffect(() => {
    if (agent) {
      const selectedAgent = agents.find((a) => a.name === agent);
      setTeam(selectedAgent ? selectedAgent.team : "");
      setAgentId(selectedAgent ? selectedAgent._id : null);
    } else {
      setTeam("");
      setAgentId(null);
    }
  }, [agent, agents]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setUploadedFiles([]);
    setFileNames([]);
    setMatchedFiles([]);
    setErrorMessage("");
  };

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      sx={{ width: "100%", height: "100%" }}
    >
      {activeStep === 0 && (
        <>
          <InitialUploadStep
            onDrop={onDrop}
            uploadedFiles={uploadedFiles}
            errorMessage={errorMessage}
            handleNext={handleNext}
            theme={theme}
          />
          <EnableMatchingCheckbox
            isMatchingEnabled={isMatchingEnabled}
            setIsMatchingEnabled={setIsMatchingEnabled}
            theme={theme}
          />
        </>
      )}
      {activeStep === 1 &&
        (isMatchingEnabled ? (
          <MatchFilesStep
            fileNames={fileNames}
            handleNext={handleNext}
            handleBack={handleBack}
            matchedFiles={matchedFiles}
            setMatchedFiles={setMatchedFiles}
            uploadedFiles={uploadedFiles}
            theme={theme}
          />
        ) : (
          <ManualSelectionForm
            language={language}
            setLanguage={setLanguage}
            agent={agent}
            setAgent={setAgent}
            agents={agents}
            team={team}
            theme={theme}
            handleNext={handleNext}
          />
        ))}
      {activeStep === 2 && (
        <UploadFilesStep
          uploading={uploading}
          setUploading={setUploading}
          handleNext={handleNext}
          handleBack={handleBack}
          matchedFiles={isMatchingEnabled ? matchedFiles : uploadedFiles}
          theme={theme}
          onUploadComplete={onUploadComplete}
          setErrorMessage={setErrorMessage}
          isMatchingEnabled={isMatchingEnabled}
          language={language}
          agentId={agentId}
        />
      )}
      {activeStep === 3 && (
        <ConfirmationStep handleReset={handleReset} theme={theme} />
      )}
    </Box>
  );
};

export default FileUploader;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  useTheme,
  Chip,
  Popover,
  CircularProgress,
  Button,
  Popper,
  TextField,
  Slider,
  Autocomplete,
  Stack,
} from "@mui/material";
import { faker } from "@faker-js/faker";
import { Warning, ArrowUpRight, Info } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useGetCallAnalyticsSummaryQuery } from "../state/api";
import ChipList from "./ChipList";

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

function formatSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  if (hours > 0) {
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  } else {
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  }
}

const CallList = ({ calls, status = "processed" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCallId, setSelectedCallId] = useState(null);

  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/callDetails?id=${id}`);
  };

  const handlePopoverOpen = (event, callId) => {
    setAnchorEl(event.currentTarget);
    setSelectedCallId(callId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedCallId(null);
  };

  const open = Boolean(anchorEl);
  const { data, error, isLoading } = useGetCallAnalyticsSummaryQuery(
    selectedCallId,
    {
      skip: !selectedCallId,
    }
  );

  const displayButtons = status === "processed" ? true : false;
  const chipColor =
    status === "processed"
      ? `${theme.palette.primary.light}`
      : `${theme.palette.background.widget}`;
  const columns =
    status === "processed"
      ? "2fr 1fr 1fr 1fr 1fr 1fr 1fr 0.2fr 1fr"
      : "1.5fr 1fr 1fr 1fr 1fr";

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* Header row */}
      <Box
        display="grid"
        gridTemplateColumns={columns}
        alignItems={"center"}
        width="100%"
        padding={1.5}
        sx={{
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          borderBottom: `1px solid ${theme.palette.background.default}`,
          borderRadius: theme.shape.borderRadius / 16,
          paddingLeft: 2,
          marginRight: 0.5,
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body" color="inherit">
            Agent
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body" color="inherit">
            Status
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body" color="inherit">
            Language
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body" color="inherit">
            Duration
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body" color="inherit">
            Date
          </Typography>
        </Box>
        {status === "processed" && (
          <>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body" color="inherit">
                Completeness
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body" color="inherit">
                AI Score
              </Typography>
            </Box>
          </>
        )}
      </Box>

      <Box
        sx={{
          flex: "1 1 auto",
          overflow: "auto",
          height: "100px",
          paddingBottom: 1,
        }}
      >
        {calls.map((call) => (
          <Box
            key={call.id}
            display="grid"
            gridTemplateColumns={columns}
            width="100%"
            padding={1}
            sx={{
              borderBottom: `1px solid ${theme.palette.background.default}`,
              borderRadius: theme.shape.borderRadius / 16,
              paddingLeft: 2,
              marginRight: 0.5,
              transition:
                "backgroundColor 2s ease-in-out, transform 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: theme.palette.background.default,
              },
            }}
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
            >
              <Avatar
                src={call.agent.avatar}
                alt={call.agent.name}
                sx={{ width: 30, height: 30 }}
              />
              <Box
                paddingLeft={1}
                display="flex"
                justifyContent="center"
                alignItems="start"
                flexDirection="column"
              >
                <Typography
                  variant="body2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {truncateString(call.agent.name, 16)}
                </Typography>
                <Typography variant="body2" color="textSecondary" noWrap>
                  {call.agent.team}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Chip
                label={call.status}
                size={"small"}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: chipColor,
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="body2"
                color="textPrimary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {call.language}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="body2"
                color="textPrimary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {formatSeconds(call.duration)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="body2"
                color="textPrimary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {call.date}
              </Typography>
            </Box>

            {status === "processed" && (
              <>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {Math.round((call.completeness + Number.EPSILON) * 100)}%
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {call.ai_score}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {call.flagged && (
                    <Warning color={theme.palette.error.main} size={16} />
                  )}
                </Box>
              </>
            )}

            <Stack
              direction={"row"}
              display={"flex"}
              justifyContent={"flex-end"}
              gap={0.5}
            >
              {displayButtons && (
                <>
                  <IconButton
                    onClick={(event) => handlePopoverOpen(event, call.id)}
                  >
                    <Info size={16} />
                  </IconButton>
                  <IconButton onClick={() => handleNavigate(call.id)}>
                    <ArrowUpRight size={16} />
                  </IconButton>
                </>
              )}
            </Stack>
          </Box>
        ))}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box p={2} sx={{ width: 300 }}>
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            <Typography variant="body2" color="error">
              Error loading data
            </Typography>
          ) : (
            <div>
              <Typography variant="h6">Summary</Typography>
              <Typography variant="body2" color="textSecondary">
                {data?.conversation_summary?.summary || "No summary available"}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Products
              </Typography>
              {data?.products ? (
                <ChipList items={data.products} random_colors={true}></ChipList>
              ) : (
                <Typography variant="body2">
                  No products were mentioned
                </Typography>
              )}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Targets
              </Typography>
              {data?.targets ? (
                <ChipList items={data.targets} random_colors={true}></ChipList>
              ) : (
                <Typography variant="body2">No targets were reached</Typography>
              )}
            </div>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default CallList;

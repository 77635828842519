import React, { useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useUploadFilesMutation } from "../../state/api";

const UploadFilesStep = ({
  uploading,
  setUploading,
  handleNext,
  handleBack,
  matchedFiles,
  theme,
  onUploadComplete,
  setErrorMessage,
  isMatchingEnabled,
  language,
  agentId,
}) => {
  const [uploadFiles] = useUploadFilesMutation();

  useEffect(() => {
    const upload = async () => {
      setUploading(true);
      const formData = new FormData();

      const validFilesData = matchedFiles
        .filter((file) => !file.error) // Filter out files with errors
        .map((file) => ({
          file: isMatchingEnabled ? file.file : file,
          source: "manual",
          language: isMatchingEnabled ? file.language : language,
          agent_id: isMatchingEnabled ? file.agent_details._id : agentId,
        }));

      validFilesData.forEach((fileData, index) => {
        formData.append(`files`, fileData.file);
        formData.append(
          `fileInfos`,
          JSON.stringify({
            source: fileData.source,
            language: fileData.language,
            agent_id: fileData.agent_id,
          })
        );
      });

      console.log("Form to send:", Array.from(formData.entries()));

      try {
        await uploadFiles(formData).unwrap();
        setUploading(false);
        onUploadComplete();
        handleNext();
      } catch (error) {
        console.error("Error uploading files:", error);
        setErrorMessage("Error uploading files. Please try again.");
        setUploading(false);
      }
    };

    upload();
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      {uploading ? (
        <CircularProgress />
      ) : (
        <Typography variant="body1" color={theme.palette.neutral.white}>
          Uploading files...
        </Typography>
      )}
    </Box>
  );
};

export default UploadFilesStep;

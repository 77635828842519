import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useMatchFilesMutation } from "../../state/api";

import { CheckCircle, XCircle } from "@phosphor-icons/react";

const MatchFilesStep = ({
  fileNames,
  handleNext,
  handleBack,
  matchedFiles,
  setMatchedFiles,
  uploadedFiles,
  theme,
}) => {
  const [matchFiles, { isLoading: uploading }] = useMatchFilesMutation();

  useEffect(() => {
    const fetchMatchingResults = async () => {
      try {
        // Sending filenames to the backend for matching
        console.log("Filenames", fileNames);
        const payload = { file_names: fileNames };
        console.log("Payload", payload);
        const response = await matchFiles(payload).unwrap();
        console.log("Matching results", response);

        // Merge original file data with the matched response
        const mergedFiles = response.map((matchedFile) => {
          const originalFile = uploadedFiles.find(
            (file) => file.name === matchedFile.file_name
          );
          return {
            ...matchedFile,
            file: originalFile,
          };
        });

        setMatchedFiles(mergedFiles);
      } catch (error) {
        console.error("Error matching agents:", error);
      }
    };

    fetchMatchingResults();
  }, [fileNames, matchFiles, setMatchedFiles, uploadedFiles]);

  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      flexDirection="column"
      py={theme.spacing(1)}
      sx={{ height: "100%" }}
    >
      {uploading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                maxHeight: "200px",
                overflowY: "auto",
                marginBottom: 2,
                padding: theme.spacing(1),
              }}
            >
              {matchedFiles.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: `1px solid ${
                      file.error
                        ? theme.palette.error.main
                        : theme.palette.success.main
                    }`,
                    borderRadius: theme.shape.borderRadius / 16,
                    padding: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                    backgroundColor: file.error
                      ? theme.palette.error.light
                      : theme.palette.success.light,
                  }}
                >
                  <Stack>
                    <Typography
                      variant="body3"
                      color={theme.palette.neutral.black}
                    >
                      File Name
                    </Typography>
                    <Typography
                      variant="body2"
                      color={theme.palette.neutral.white}
                    >
                      {file.file_name}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body2"
                      color={theme.palette.text.secondary}
                    >
                      Agent
                    </Typography>
                    <Typography
                      variant="body1"
                      color={theme.palette.neutral.white}
                    >
                      {file.agent_details?.name || "N/A"}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body2"
                      color={theme.palette.text.secondary}
                    >
                      Language
                    </Typography>
                    <Typography
                      variant="body1"
                      color={theme.palette.neutral.white}
                    >
                      {file.language || "N/A"}
                    </Typography>
                  </Stack>
                  <Box display="flex" alignItems="center" padding={1}>
                    {file.error ? (
                      <XCircle size={20} color={theme.palette.neutral.white} />
                    ) : (
                      <CheckCircle
                        size={20}
                        color={theme.palette.neutral.white}
                      />
                    )}
                    {/* <Typography
                      variant="body2"
                      color={
                        file.error
                          ? theme.palette.error.main
                          : theme.palette.success.main
                      }
                      ml={1}
                    >
                      {file.error ? file.error : "Match successful"}
                    </Typography> */}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={handleBack}>Back</Button>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ color: theme.palette.neutral.black }}
            >
              Confirm
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MatchFilesStep;

import React from "react";
import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";

const gradientShadow = (primaryColor, secondaryColor) => {
  return `0 0 10px ${primaryColor}, 0 0 20px ${secondaryColor}`;
};

const CustomFab = styled(Fab)(({ theme, active }) => {
  const isDarkMode = theme.palette.mode === "dark";
  const activeColor = `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`;
  // const boxShadowColor = isDarkMode
  //   ? "rgba(255, 255, 255, 0.2)"
  //   : "rgba(0, 0, 0, 0.6)";
  const boxShadowColor = "rgba(0, 0, 0, 0.2)";
  return {
    boxShadow: `0 0 10px 3px ${boxShadowColor}`, // Reduced shadow when not active
    background: active
      ? activeColor
      : isDarkMode
      ? theme.palette.background.widget
      : theme.palette.neutral.black, // Background color based on theme mode
    color: theme.palette.neutral.white, // Text color based on theme mode
    "&:hover": {
      //   boxShadow: `0px 0px 3px 2px ${theme.palette.primary.main}`, // Custom hover shadow
      background: isDarkMode
        ? theme.palette.background.default
        : theme.palette.neutral.white, // Background color on hover based on theme mode
      color: isDarkMode
        ? theme.palette.neutral.white
        : theme.palette.neutral.gray, // Text color on hover based on theme mode
    },
  };
});

export default CustomFab;
